import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class ThroughLighting extends Component {
	componentDidMount() {
		PointerEvents();
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Signage Through Lights & Trough Lighting in London</title>
					<meta
						name="description"
						content="Trough Lights - Trough lighting is used with shop and retail signs and can be powder coated to any RAL or Pantone for supply and install in and around London"
					/>
				</Helmet>
				<HeaderSimple
					heading="TROUGH LIGHTS"
					subtitle="TROUGH LIGHTING IS USUALLY USED FOR SHOP OR RETAIL SIGNS WHERE THERE IS NO INTERNAL ILLUMINATION. BLACK OR WHITE sLIMLINE LIGHTS ARE MOST POPULAR BUT A WIDE RANGE OF COLOURS ARE AVAILABLE, WITH FINISHES IN MATT, SATIN OR GLOSS FINISH."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/12-Trough-Lighting/BlackTroughLights.jpg"
							title="Black Trough Lights"
						/>
						<ServicesGalleryImg
							img="/img/signselection/12-Trough-Lighting/SlimlineBlackTroughLights.jpg"
							title="Slimline Black Trough Lights"
						/>
						<ServicesGalleryImg
							img="/img/signselection/12-Trough-Lighting/SlimlineBlackTroughLights2.jpg"
							title="Slimline Black Trough Lights"
						/>
						<ServicesGalleryImg
							img="/img/signselection/12-Trough-Lighting/SlimlineGreyTroughLights.jpg"
							title="Slimline Grey Trough Lights"
						/>
						<ServicesGalleryImg
							img="/img/signselection/12-Trough-Lighting/SlimlineWhiteTroughLights.jpg"
							title="Slimline White Trough Lights"
						/>
						<ServicesGalleryImg
							img="/img/signselection/12-Trough-Lighting/POWDER_COATED_SLIMLINE_TROUGH_LIGHT.jpg"
							title="POWDER_COATED_SLIMLINE_TROUGH_LIGHT"
						/>
						<ServicesGalleryImg
							img="/img/signselection/12-Trough-Lighting/POWDER_COATED_DOUBLE_SLIMLINE_LED_TROUGH_LIGHTS.jpg"
							title="POWDER_COATED_DOUBLE_SLIMLINE_LED_TROUGH_LIGHTS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/12-Trough-Lighting/POWDER_COATED_PROJECTING_SIGN_WITH_DOUBLE_SIDED_TROUGH_LIGHTS.jpg"
							title="POWDER_COATED_PROJECTING_SIGN_WITH_DOUBLE_SIDED_TROUGH_LIGHTS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/12-Trough-Lighting/POWDER_COATED_SLIMLINE_LED_TROUGH_LIGHT.jpg"
							title="POWDER_COATED_SLIMLINE_LED_TROUGH_LIGHT"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Everything has pros and cons. Whether we like it or not, this happens in business
									too. There&rsquo;s nothing worse than spending money on a piece of physical,
									visual marketing where it can only be appreciated for 12 hours of the day. After
									the time you&rsquo;ve spent perfecting your final design it would be a huge shame
									if it was less effective. We would love to say that we could solve everything but
									that might be an overstatement to say the least! However, to keep you illuminated
									24/7 and highlight your business in the best way we know how, our signature trough
									lighting will do the job.
								</p>

								<p>
									Trough lighting is basically the lightbulb to your business. We take your choice
									of lighting and fit it onto the external or overhead of your sign to provide an
									illuminated glow over or aside your sign which offers higher levels of visibility
									even in the darkest of nights. Since new signage can be expensive and might not be
									in your budget, we don&rsquo;t want to leave you without a solution to 24/7
									advertising and trough lighting is a cheap alternative to purchasing a new
									illuminated sign. Trough lighting also gives a sleek and professional look to your
									business and translates a sense of consideration and clarity to your consumers to
									keep them looking for you on the high street.
								</p>

								<p>
									But as a London based business, we know how unreliable the weather can be.
									Luckily, our trough lighting is weather resistance regardless of rain, sleet or
									snow, so you have less to maintain. So how will this benefit you? Being able to
									access your consumer regardless of the time of day leaves you accessible to a more
									successful business. Whether your consumer is returning home after a late one in
									the office on a winters night or a family are on their way home from a dinner
									nearby, being able to convey your brand visibility regardless of the time of day
									is imperative in achieving commercial success.
								</p>

								<p>
									Where do we come in? We here, at Yellow Signs, produce almost all of everything
									that we do - in-house at our Battersea production centre - on the regular so that
									we can complete a quick and efficient turnaround of products. So much so, that we
									can get your graphics and signage and everything in between, such as your trough
									lighting, in London, where ever you are! Sound good? It does to us too!
								</p>

								<p>
									To see more of our past work, please visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									If you&rsquo;re interested in shining a light on your business with us &ndash;
									using graphics and signage &ndash; please contact one of our signage experts on
									020 7350 1567 or email{" "}
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(ThroughLighting);
