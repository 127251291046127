import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class NeonSigns extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Neon Signs in London, Neon Signs Specialist in Battersea</title>
					<meta
						name="description"
						content="LED Neons Signs for Businesses in London - We offer LED flex neons signs with solid colour or transparent backs fabricated by us in Battersea, London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Neon Signs"
					subtitle="Not only are LED neon signs more environmentally friendly, but they are also much safer than regular neon signs so you can rest assured you are exposing your consumers to a safe and approved form of signage. For eco-friendly businesses, an LED neon sign is a fantastic form of marketing. People feel inclined to post on social media with the trendy style and colour palette and in the age of eco-friendly and cruelty free life, a form of décor like this is considered quirky and respectable."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/6-Neon-Signs/pink-led-neon.jpg"
							title="Pink LED Neon"
						/>
						<ServicesGalleryImg
							img="/img/signselection/6-Neon-Signs/yellow--white-led-neon.jpg"
							title="Yellow & White LED Neon"
						/>
						<ServicesGalleryImg
							img="/img/signselection/6-Neon-Signs/green-led-neon.jpg"
							title="Green LED Neon"
						/>
						<ServicesGalleryImg
							img="/img/signselection/6-Neon-Signs/CEILING_WHITE_LED_NEON_WITH_MIRROR_BACKGROUND.jpg"
							title="CEILING_WHITE_LED_NEON_WITH_MIRROR_BACKGROUND"
						/>
						<ServicesGalleryImg
							img="/img/signselection/6-Neon-Signs/FAUX_LED_NEON.jpg"
							title="FAUX_LED_NEON"
						/>
						<ServicesGalleryImg
							img="/img/signselection/6-Neon-Signs/DOUBLE_FLEX_LED_NEON.jpg"
							title="DOUBLE_FLEX_LED_NEON"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									If you&rsquo;ve ventured onto Instagram in recent years, you'll have seen a wide
									range of posts shared, liked and commented on. From lunchtime pics to nights out,
									social media has become a haven for people to share their daily lives for others
									to see. But social media doesn&rsquo;t just benefit the general public: it is also
									a fantastic tool for businesses to get themselves out there and attract a new
									group of potential customers. Whilst there are plenty of ways to make yourself a
									hashtable business, one of our favourite ways of getting those location tags is
									neon signs.
								</p>

								<p>
									In recent years neon has become a growing trend: With the colours being vibrant
									and exciting, we love posting pics or sharing snaps of aesthetically pleasing
									places and things. You might be asking; how will this help me? You can utilise
									your internal or external signage for your business to drive those customers to
									produce guerrilla advertising for your business. For those not clued in with the
									ins and outs of marketing, guerrilla advertisement are non-conventional ways to
									drive brand awareness with the use of design. When consumers seek something new,
									LED neon does the job! So, let&rsquo;s capture a snapshot of your business in the
									best way we know how.
								</p>

								<p>
									So, what is neon signage? Neon signs involve brightly-coloured &ndash; think of
									your favourite highlighter stationary - lighting suspended in either bulbs or
									tubes in a variety of shapes to fit a brand aesthetic. It ranges in size, colour,
									text and shape and can be used in a variety of different ways to shine a spotlight
									on your business. Since safety and sustainability are paramount to our business,
									we provide a sustainable and safer alternative to neon using LED neon to ensure we
									are exposing your customers to only the safest form of signage! But don&rsquo;t
									worry, LED neon doesn&rsquo;t compromise your creativity at all; in fact,
									you&rsquo;ll have even more colour range and keep the environmentally conscious in
									mind!
									<br />
									<br />
									To see some of our past work, feel free to visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									At Yellow Signs, we produce essentially everything we do in our Battersea HQ so we
									can supply our final products quickly and timely. Since we understand the
									importance of great signage, we want to get your signage and graphics, especially
									the neon variety, to you, anywhere in London, to get those Instagram highlights up
									and running! If you&rsquo;re struggling for inspiration or looking for something
									more bespoke, feel free to contact our team who will be happy to help you create
									something you and your customers will love.
								</p>

								<p>
									If you&rsquo;re interested in highlighting your business with us&ndash; using
									graphics and signage &ndash; please contact one of our signage experts at 020 7350
									1567 or email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(NeonSigns);
