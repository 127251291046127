import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const LogoRow = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
	font-weight: 600;
	& p {
		margin: 0;
	}
	& img {
		height: 3rem;
	}
`;

class PlaquesWayfinding extends Component {
	componentDidMount() {
		PointerEvents();
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Wayfinding in London & Plaques in London</title>
					<meta
						name="description"
						content="Wayfinding for Businesses in London - We fabricate wayfinding plaques in a variety of materials including acrylic plaques and metal plaques in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Wayfinding & Plaques"
					subtitle="Plaques and general wayfinding is the best way to encourage people to use a particular route or draw attention to areas or aspects of your business. Plaques are available in a variety of materials and thicknesses including acrylic and solid metal."
				/>
				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/plaques-wayfinding-1.jpg"
							title="ACRYLIC PLAQUES & FULL COLOUR ALUMINIUM COMPOSITE PANELS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/plaques-wayfinding-2.jpg"
							title="BLACK FILLED ETCHED STAINLESS STEEL PLAQUE"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/plaques-wayfinding-3.jpg"
							title="BLUE FILLED ETCHED BRUSHED STAINLESS STEEL PLAQUES"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/plaques-wayfinding-4.jpg"
							title="CLEAR ACRYLIC PLAQUE WITH FULL COLOUR PRINT TO REVERSE ON STUDS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/plaques-wayfinding-5.jpg"
							title="CLEAR ACRYLIC PLAQUES WITH FROST & FULL COLOUR PRINT TO REVERSE ON STUDS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/plaques-wayfinding-6.jpg"
							title="CLEAR ACRYLIC PLAQUES WITH FULL COLOUR PRINT TO REVERSE ON STUDS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/plaques-wayfinding-7.jpg"
							title="CLEAR ACRYLIC WAYFINDING PLAQUES WITH FULL COLOUR PRINT TO REVERSE ON STUDS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/plaques-wayfinding-8.jpg"
							title="FULL COLOUR & BLACK FILLED ETCHED STAINLESS STEEL PLAQUE"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/plaques-wayfinding-9.jpg"
							title="FULL COLOUR PRINTED ALUMINIUM COMPOSITE TRAFFIC SIGNS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/BRUSHED_STAINLESS_STEEL_PLAQUES_WITH_COLOUR_IN-FILL.jpg"
							title="BRUSHED_STAINLESS_STEEL_PLAQUES_WITH_COLOUR_IN-FILL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/POWDER_COATED_ALUMINUM_PLAQUES_WITH_WHITE_VINYL_LOGO.jpg"
							title="POWDER_COATED_ALUMINUM_PLAQUES_WITH_WHITE_VINYL_LOGO"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/8MM_CLEAR_ACRYLIC_PLAQUES_WITH_FULL_COLOUR_PRINT_TO_BACK_&_STUDS.jpg"
							title="8MM_CLEAR_ACRYLIC_PLAQUES_WITH_FULL_COLOUR_PRINT_TO_BACK_&_STUDS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/PHOTOLUMINESCENT_ACRYLIC_PLAQUE_WITH_BLACK_CUT_VINYL_APPLIED_TO_FACE.jpg"
							title="PHOTOLUMINESCENT_ACRYLIC_PLAQUE_WITH_BLACK_CUT_VINYL_APPLIED_TO_FACE"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/ALUMINUM_COMPOSITE_WAYFINDING_SIGNS.jpg"
							title="ALUMINUM_COMPOSITE_WAYFINDING_SIGNS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Plaques-Wayfinding/ANTI-GRAFFITI_HOARDING.jpg"
							title="ANTI-GRAFFITI_HOARDING"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									When you drive down a one-way street, what informs you that you're going the right
									way? Do you live in the area and know the location? Or perhaps your friend clued
									you in. Usually, it's the signage and graphics the government have propped up on
									the roadside that guides you into making the discovery, choosing the correct route
									and preventing a pesky and unnecessary fine. This concept is well-known among most
									and wayfinding signage is everywhere, even when you&rsquo;re not looking for it.
									However, wayfinding signage isn't just applied on the roads or in the traffic but
									often in business, commercial and even domestic spaces.
								</p>

								<p>
									Let&rsquo;s take a look at what we mean by wayfinding signage. Wayfinding signage
									combines forms of directional signage and graphics and presents them in a variety
									of colours, styles and methods to move people in the particular direction you
									want. One of the most popular items we produce is wayfinding plaques which are the
									perfect wall-mounted form of signage to inform guests, customers, employees and
									clients where to go to receive the service they are looking for. It works very
									well near escalators as it helps navigate the abundance of floors to sort through.
									However, there are also more creative ways of creating an impact and directing
									people.
								</p>

								<p>
									When holding an event, often the venue is unfamiliar to guests making it difficult
									for them to navigate. By installing aluminium composite panelling on a wall or a
									large plaque, you can maximise your brand creativity with colour and shape to
									point people in the direction of your space. This can include text, arrows and
									even maps to create a professional and well-organised event or exhibition; we also
									offer a variety of materials from aluminium and vinyl to acrylic.
								</p>

								<p>
									Your plaque choices are not just limited to directional signage either. You can
									use plaques to inform or to lay out rules to keep your customers or visitors in
									check. One of the ways you may see this implemented is in health and safety
									signage. From emergency exit signage for hospitality, business, and educational
									spaces to danger signs to prevent injury at construction sites. Is your space a
									smoke-free zone? Then make it known! Plaques are a great way to keep people safe
									and maintain order.
								</p>

								<p>
									To view some of our past work, please visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link> <br />
									<br />
									Where do we come in? Since we are a London-based business, our team produce almost
									everything we do in our Battersea production centre to be timely and efficient. We
									know London is a fast-paced working environment you need a quick turnaround of
									products to help your business function smoothly so we work in-house to get your
									signage and graphics to you, anywhere in London. If you would like some design
									help or are seeking something bespoke, feel free to contact our team who will be
									more than happy to assist you.
								</p>

								<p>
									If you&rsquo;re interested in producing your signage with us, please contact one
									of our signage experts at 020 7350 1567 or email{" "}
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>{" "}
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
								<LogoRow>
									<p>We are an official approved supplier for:</p>
									<img src="/img/signselection/Plaques-Wayfinding/battersea-logo-col.jpg" />
									<img src="/img/signselection/Plaques-Wayfinding/savills-col-logo.jpg" />
									<img src="/img/signselection/Plaques-Wayfinding/kfh-logo-col.jpg" />
								</LogoRow>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(PlaquesWayfinding);
