import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class BarRestaurant extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Signs for Bars & Restaurants in London, Signage Specialist</title>
					<meta
						name="description"
						content="Bars and restaurants in London can benefit from new signage and graphics to promote the business and encourage more traffic - we supply and install signs in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Bar & Restaurant"
					subtitle="Popular signage for bars and restaurants includes menus, fascia sign, window graphics and floor decals. Bar and restaurant signs help build the brand as well as serving as direction signage and pricelists for food and drink. We work with a large number of bars and restaurants, from small single premises outfits to large multi-site businesses."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/wet-coated-matt-orange-10mm-acrylic-letters.jpg"
							title="Wet Coated Matt Orange 10mm Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/wet-coated-matt-black-5mm-letters-on-stand-off-locators.jpg"
							title="Wet Coated Matt Black 5mm Letters on Stand-Off Locators"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/pink-led-neon.jpg"
							title="Pink LED Neon"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/full-colour-digitally-printed-wallpaper.jpg"
							title="Full Colour Digitally Printed Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/full-colour-digitally-printed-lightbox-menus.jpg"
							title="Full Colour Digitally Printed Lightbox Menus"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/green-led-neon.jpg"
							title="Green LED Neon"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/fridge-vinyl-wrap.jpg"
							title="Fridge Vinyl Wrap"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/aluminium-composite-sign-tray-trough-light--window-graphics.jpg"
							title="Aluminium Composite Sign, Tray, Trough, Light & Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/cut-vinyl-letters-&-suspended-clear-acrylic-roundel.jpg"
							title="Cut Vinyl Letters & Suspended Clear Acrylic Roundel"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/contour-cut-full-colour-digitall-printed-window-graphics.jpg"
							title="Contour Cut Full Colour Digitall Printed Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/aluminium-composite-panel-faced-with-vinyl.jpg"
							title="Aluminium Composite Panel Faced with Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/5mm-acrylic-letters--full-colour-window-graphics.jpg"
							title="5mm Acrylic Letters & Full Colour Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/3DBuiltUpLettersonTray.jpg"
							title="3D Built-Up Letters on Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/3DBuiltUpRimReturnFrontIlluminated.jpg"
							title="3D Built-Up Rim & Return Front Illuminated"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/AcrylicBackedIlluminatedAluminiumCompositeTray.jpg"
							title="Acrylic Backed Illuminated Aluminium Composite Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/CNCRoutedWood.jpg"
							title="CNC Routed Wood"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/CNCRoutedWoodStencilPainted.jpg"
							title="CNC Routed Wood & Stencil Painted"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/CutVinylWrappedMDF.jpg"
							title="Cut Vinyl Wrapped MDF"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/CutVinylWrappedMDF2.jpg"
							title="Cut Vinyl Wrapped MDF"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/FlatAcrylicLettersonWood.jpg"
							title="Flat Acrylic Letters on Wood"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/FlatCutSolidWood.jpg"
							title="Flat Cut Solid Wood"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/FullColourWallpaper.jpg"
							title="Full Colour Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/WET_COATED_ACRYLIC_LETTERS_AND_CUT_VINYL_WINDOW_GRAPHICS.jpg"
							title="WET COATED ACRYLIC LETTERS AND CUT VINYL WINDOW GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/TEMPORARY_FULL_COLOUR_PRINTED_VINYL.jpg"
							title="TEMPORARY_FULL_COLOUR_PRINTED_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/INTERNALLY_ILLUMINATED_LOCKABLE_LIGHTBOX.jpg"
							title="INTERNALLY_ILLUMINATED_LOCKABLE_LIGHTBOX"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/FULL_COLOUR_PRINTED_WRAP_VINYL.jpg"
							title="FULL_COLOUR_PRINTED_WRAP_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/WET_COATED_ACRYLIC_LETTERS_AND_CUT_VINYL_WINDOW_GRAPHICS.jpg"
							title="WET_COATED_ACRYLIC_LETTERS_AND_CUT_VINYL_WINDOW_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/INTERNALLY_ILLUMINATED_OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_WET_COATED_ACRYLIC_LOGO.jpg"
							title="INTERNALLY_ILLUMINATED_OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_WET_COATED_ACRYLIC_LOGO"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE.jpg"
							title="WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY.jpg"
							title="PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/GOLD_WET_COATED_5MM_ACRYLIC_LETTERS_APPPLIED_DIRECTLY_TO_FASCIA.jpg"
							title="GOLD_WET_COATED_5MM_ACRYLIC_LETTERS_APPPLIED_DIRECTLY_TO_FASCIA"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/HIGH-TACK_WHITE_CUT_VINYL.jpg"
							title="HIGH-TACK_WHITE_CUT_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO_ILLUMINATION.jpg"
							title="25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO_ILLUMINATION"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO__ILLUMINATION_-_WORKSHOP.jpg"
							title="25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO__ILLUMINATION_-_WORKSHOP"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/FAUX_LED_NEON.jpg"
							title="FAUX_LED_NEON"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/1-Bar-Restaurant/POWER_COATED_ALUMINIUM_TRAY_&_TROUGH_LIGHT_WITH_BRUSHED_GOLD_LETTERS.jpg"
							title="POWDER COATED ALUMINIUM TRAY & TROUGH LIGHT WITH BRUSHED GOLD LETTERS"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Wherever we are, we all know the feeling of going out to a bar or restaurant.
									Putting on something nice to wear, meeting up with your friends for a bite to eat
									or a drink with co-workers and checking our bank balances the morning after with
									dread! But what was it that enticed you to that bar or restaurant in the first
									place? Was it the location? Menu? Or maybe the price? Well, whatever it was just
									sealed the deal, WEguarantee that you saw something that made that bar a winner.
									For business owners, you want your customers to feel this every time your shop is
									open to achieve success. Luckily, signage and graphics are one of the easiest ways
									that you can elevate your hospitality game and keep the drinks flowing.
								</p>

								<p>
									There&rsquo;s a wide variety of signage and graphics you can utilise in your
									hospitality business to create a united theme both internally and externally in
									your business. From stunning digitally printed menus to LED neon signs to draw in
									a quirky crowd. At Yellow Signs, we provide plenty of methods to shine a light on
									your business 24/7. One of our favourite ways to build your brand image and to
									direct your customers to your store is using window graphics which are
									customisable to your every whim. We typically use vinyl as a fantastic
									cost-cutting alternative to graphics and allow you a wide range of creative
									diversity. Signage is a great option to engage your customers and offer a
									competitive edge.
								</p>

								<p>
									We recognise that no two bars are alike; as such, we want to be able to offer you
									products that align with your brand image. Whether you are a well-known chain of
									bars or restaurants or a rustic and independent family-run business, rest assured
									we have endless choices for every brand identity. For the quirkier bunch, wild
									colours and designs to tailor to a new or current identity e.g. illuminated
									signage to floor decals. And what about the luxury market? Our built-up and facia
									signs are the perfect way to become iconographic at your business location to
									direct your customers to enjoy your food and drinks.
								</p>

								<p>
									To view, some of our past work, visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									Yellow Signs is a business firmly rooted in London so we know how diverse brands
									can be! We love to help new and old brands increase their brand visibility and
									customer profiles. We produce almost all of our products in-house, in our
									Battersea headquarters to provide your signage and graphics, especially for the
									hospitality industry, in London speedily; all so you can get back to what you do
									best!
								</p>

								<p>
									If you&rsquo;re interested in feeding your business &ndash; using graphics and
									signage &ndash; please contact one of our signage experts on 020 7350 1567 or
									email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(BarRestaurant);
