import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";

class OfficeCorporate extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<HeaderSimple
					heading="Office & Corporate"
					subtitle="Privacy frosting is often essential as part of good health and safety practice. Wayfinding is often used for larger and shared offices, as well as signage for meeting rooms and other communal areas. Window graphics are wide format prints and lightboxes are also popular for offices and corporate premises."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/wet-coated-matt-black-5mm-acrylic-letters.jpg"
							title="Wet Coated Matt Black 5mm Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/cut-to-shape-frosted-vinyl--cut-orange-vinyl.jpg"
							title="Cut to Shape Frosted Vinyl + Cut Orange Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/wet-coated-5mm-acrylic-letters-on-stand-off-locators.jpg"
							title="Wet Coated 5mm Acrylic Letters on Stand-Off Locators"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/cut-vinyl-window-graphics.jpg"
							title="Cut Vinyl Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/3DBuiltupIlluminatedLetters.jpg"
							title="3D Built-up Illuminated Letters"
						/>
						{/* <ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/3dBuiltUpLetters.jpg"
							title="3d Built-Up Letters"
						/> */}
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/BuiltUpPolishedStainlessSteelLetters.jpg"
							title="Built-Up Polished Stainless Steel Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/CutVinyl.jpg"
							title="Cut Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/CutVinylFrostedVinylPlaques.jpg"
							title="Cut Vinyl & Frosted Vinyl Plaques"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/FrostedVinyl.jpg"
							title="Frosted Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/FullColourVinylCutVinyl.jpg"
							title="Full Colour Vinyl & Cut Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/FullColourWallpaper.jpg"
							title="Full Colour Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/FullColourWallpaper2.jpg"
							title="Full Colour Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/FullColourWallpaper3.jpg"
							title="Full Colour Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/FullColourWallpaperPanels.jpg"
							title="Full Colour Wallpaper Panels"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/ReverseCutFrostedVinyl.jpg"
							title="Reverse Cut Frosted Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/WEAT_COATED_AND_FULL_COLOUR_PRINTED_CUT_ACRYLIC.jpg"
							title="WET COATED AND FULL COLOUR PRINTED CUT ACRYLIC"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/WET_COATED_10MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS.jpg"
							title="WET_COATED_10MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/FULL_COLOUR_PRINTED_HIGH-TACK_VINYL_GRAPHICS.jpg"
							title="FULL_COLOUR_PRINTED_HIGH-TACK_VINYL_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/16MM_SILICON_EDGE_FULL_COLOUR_PRINTED_GRAPHICS.jpg"
							title="16MM_SILICON_EDGE_FULL_COLOUR_PRINTED_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/TEAL_CUT_VINYL_WINDOW_GRAPHICS.jpg"
							title="TEAL_CUT_VINYL_WINDOW_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/FULL_COLOUR_PRINTED_WALLPAPER_VINYL.jpg"
							title="FULL_COLOUR_PRINTED_WALLPAPER_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/FULL_COLOUR_PRINTED_VINYL_MANIFESTATIONS.jpg"
							title="FULL_COLOUR_PRINTED_VINYL_MANIFESTATIONS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/CUT_PRIVACY_FROSTED_VINYL.jpg"
							title="CUT_PRIVACY_FROSTED_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/6-Office-Corporate/WET_COATED_10MM_ACRYLIC_LETTERS_WITH_FULL_COLOUR_PRINTED_VINYL_TO_FACE.jpg"
							title="WET_COATED_10MM_ACRYLIC_LETTERS_WITH_FULL_COLOUR_PRINTED_VINYL_TO_FACE"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									For office-based businesses, displaying a degree of professionalism is essential
									in fostering a successful work environment. From the second a client or new
									employee steps into your office, it should set the tone and standard for your
									business and convey the exact tone you want to pass along. From privacy to
									direction, your intentions in business should be clear and what better way to
									portray this than through striking new signage and graphics?
								</p>

								<p>
									One of our favourite ways to up your graphics game in more formal settings whilst
									offering a practical benefit is through privacy frosting. This process is achieved
									by using a sheer-opaque or &lsquo;frosted&rsquo; window sticker which blurs or
									masks the visuals. This can vary in blurriness so you can control how much or
									little you would like people to view without compromising on your lighting or
									accessibility. This is a fantastic way to spice up a potentially bland office
									space, add a sense of luxury and most importantly, shield sensitive information
									which I&rsquo;m sure your clients will value.
								</p>

								<p>
									Similarly, wayfinding signage is great for those large, tricky-to-navigate office
									spaces; by creating a simplistic work environment, both employees will be able to
									get where they need to be and clients will feel at ease with your business. Nobody
									likes getting lost and these added details can enhance your overall experience
									from the second you enter the door to the moment you leave and everything in
									between. But aside from the practical signage and graphics, what can you include
									in your corporate space to represent your business? How about your name?! Acrylic
									letters are the perfect way to translate your business into your location. It
									offers a degree of reliability and professionalism as well as just making your
									office space stylish.
								</p>

								<p>
									At Yellow Signs, we know how important corporate spaces in London are. We also
									know that receiving your graphics in good time is incredibly crucial. That is why
									we produce almost all of our products in our Battersea production centre meaning
									we have a quick turnaround of products. Since our signage for the corporate sector
									is in high demand, we produce our products in-house so we can supply your signage
									and graphics, especially for office spaces and corporate buildings, anywhere in
									London, in little to no time at all. If you are looking for something more bespoke
									or need some design help to push your office space over the edge, feel free to
									contact our team who will be more than happy to assist you.
								</p>

								<p>
									For more information on our past work, please visit{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									If you&rsquo;re interested in enhancing your corporate space with us &ndash; using
									graphics and signage &ndash; please contact one of our signage experts at 020 7350
									1567 or email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(OfficeCorporate);
