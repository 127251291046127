import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import device from "../common/device";
import TopMenu from "./TopMenu";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const TopWrapper = styled.div`
	background: var(--ys-teal);
`;
const MidWrapper = styled.div`
	background: rgb(0, 0, 0);
	background: linear-gradient(90deg, rgba(0, 0, 0, 1) 50%, rgba(8, 175, 164, 1) 50%);
	color: white;
	border-bottom: 2px solid var(--ys-yellow);
`;
const TopBarContent = styled.p`
	color: white;
	font-size: 0.705rem;
	text-align: center;
	padding: 0.35rem 9.3rem;
	margin-bottom: 0;

	@media ${device.sm} {
		padding: 0.5rem 0;
		font-size: 0.76rem;
		text-align: center;
	}

	@media ${device.xs} {
		padding: 0.5rem 0;
		font-size: 0.65rem;
		text-align: center;
		height: 2.8rem;
	}
`;

const MobOnly = styled.div`
	z-index: 10;
	display: none;
	@media ${device.xs} {
		display: flex;
	}
`;

const DesktopOnly = styled.div`
	display: block;
	@media ${device.xs} {
		display: none;
	}
`;

const ContactWrap = styled.div`
	position: relative;
	background: var(--ys-teal);
	height: 100%;
	clip-path: polygon(5% 0, 101% 0%, 101% 100%, 0% 100%);
	padding: 0.25rem 0rem 1rem 0;
	max-height: 6.6rem;
	text-align: right;
	z-index: 4;
	@media ${device.sm} {
		clip-path: none;
		text-align: center;
		padding: 0 0.5rem 0.5rem 0.5rem;
	}
`;

const LogoWrap = styled.div`
	background: black;
`;

const SocialWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: linear-gradient(175deg, rgba(255, 234, 117, 1) 11%, rgba(255, 216, 0, 1) 47%);
	height: 100%;
	clip-path: polygon(7% 0, 100% 0%, 93% 101%, 0% 101%);
	padding: 2rem 1.6rem 2rem 1.75rem;
	text-align: center;

	@media ${device.sm} {
		justify-content: flex-end;
		clip-path: none;
		padding: 0;
		width: 100%;
		margin: 0;
		text-align: right;
	}

	& svg {
		margin-top: 0.5rem;
	}

	// @media ${device.xl} {
	//   padding: 2rem 0rem 2rem 1rem;
	// };
`;

const MenuWrapper = styled.div`
	background: var(--ys-yellow);
	margin-top: -1px;
`;

const Logo = styled.div`
	text-align: center;
	/* padding-left: 3rem; */
	@media ${device.sm} {
		padding-left: 0;
	}
`;

const TelNo = styled.a`
	color: white !important;
	font-family: Alternate, "sans-serif";
	font-size: 2rem;
`;

const Hours = styled.div`
	font-size: 0.75rem;
	margin-top: -0.4rem;
`;

const Email = styled.a`
	font-size: 0.75rem;
	margin-top: 0rem;
	color: white !important;
	display: block;
	margin-left: 2.9rem;
	@media ${device.sm} {
		margin-left: 0;
	}
`;

const WhatsappImgGreen = styled.img`
	height: 1.5rem;
	margin: 0.5rem;
`;

const SmallTxt = styled.span`
	font-size: 0.8rem;
`;

const logoImg = {
	width: "17rem",
	padding: "1.6rem",
};

const socialImgInsta = {
	color: "black",
	width: "1.5rem",
	height: "1.5rem",
};

const socialImgWhatsapp = {
	color: "black",
	width: "1.5rem",
	height: "1.5rem",
};

const socialImgFb = {
	color: "black",
	width: "1.3rem",
	height: "1.3rem",
};

class Header extends Component {
	render() {
		return (
			<div>
				<TopWrapper>
					<Container>
						<Row>
							<Col>
								<TopBarContent>
									Family owned and based in Battersea, we are London’s signage, graphics and large
									format specialist
								</TopBarContent>
							</Col>
						</Row>
					</Container>
				</TopWrapper>

				<MidWrapper>
					<Container>
						<Row style={{ background: "black" }}>
							<Col
								lg={2}
								style={document.body.clientWidth < device.small ? { padding: "0" } : null}
							>
								<DesktopOnly>
									<SocialWrap>
										<a
											href="https://www.facebook.com/YellowSignsUK/"
											target="_blank"
											rel="noopener noreferrer"
										>
											<FontAwesomeIcon style={socialImgFb} icon={faFacebookF} className="faIcon" />
										</a>
										<a
											href="https://www.instagram.com/yellow_signs/"
											target="_blank"
											rel="noopener noreferrer"
										>
											<FontAwesomeIcon
												style={socialImgInsta}
												icon={faInstagram}
												className="faIcon"
											/>
										</a>

										<Link to="/contact-us">
											<FontAwesomeIcon
												style={socialImgWhatsapp}
												icon={faWhatsapp}
												className="faIcon"
											/>
										</Link>
									</SocialWrap>
								</DesktopOnly>
								<MobOnly>
									<SocialWrap>
										<a href="https://wa.me/4407539269999" style={{ zIndex: "10" }}>
											<SmallTxt>Whatsapp Photos</SmallTxt>
											<WhatsappImgGreen src="./img/common/whatsapp.svg" />
										</a>
									</SocialWrap>
								</MobOnly>
							</Col>
							<Col lg={8} style={{ padding: 0 }}>
								<LogoWrap>
									<Link to="/">
										<Logo>
											<img
												draggable="false"
												style={logoImg}
												alt="Yellow Signs Logo"
												src={"./img/common/yellow-signs-logo.svg"}
											/>
										</Logo>
									</Link>
								</LogoWrap>
							</Col>
							<Col
								lg={2}
								style={{
									background: "black",
									padding: 0,
								}}
							>
								<ContactWrap>
									<TelNo href="tel:02073501567">020 7350 1567</TelNo>
									<Hours>8am – 5pm / Mon – Fri</Hours>
									<Email href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</Email>
								</ContactWrap>
							</Col>
						</Row>
					</Container>
				</MidWrapper>

				<MenuWrapper>
					<Container style={{ marginTop: "-2px" }}>
						<TopMenu />
					</Container>
				</MenuWrapper>
			</div>
		);
	}
}

export default withRouter(Header);
