import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class VehicleGraphics extends Component {
	componentDidMount() {
		PointerEvents();
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Vehicle Graphics in London & Vehicle Wrapping in London</title>
					<meta
						name="description"
						content="Vehicle Graphics & Van Wrapping in London - We offer cut vinyl car and van graphics as well as full colour printed vehicle graphics and van wraps in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Vehicle Graphics"
					subtitle="A PERFECT MARKETING TOOL AND AVAILABLE IN FLAT cuy VINYL, full colour contour cut vinyl, partial wrap OR FULL WRAPS. VEHICLE GRAPHICS CAN TRANSFORM A CAR, VAN OR any vehicle AND INCREASE BRAND AWARENESS or help with specific promotions."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/cut-vinyl--full-colour-contour-cut-vinyl-lorry-graphics.jpg"
							title="Cut Vinyl & Full Colour Contour Cut Vinyl Lorry Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/cut-vinyl--full-colour-contour-cut-vinyl-vehicle-graphics.jpg"
							title="Cut Vinyl & Full Colour Contour Cut Vinyl Vehicle Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/cut-vinyl--full-colour-vinyl-van-graphics.jpg"
							title="Cut Vinyl & Full Colour Vinyl Van Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/cut-vinyl-van-graphics.jpg"
							title="Cut Vinyl Car Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylCarGraphics.jpg"
							title="Cut Vinyl Car Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylCarGraphics3.jpg"
							title="Cut Vinyl Car Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylFoodTruckGraphics.jpg"
							title="Cut Vinyl Food Truck Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylFullColourCarGraphics.jpg"
							title="Cut Vinyl & Full Colour Car Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylFullColourCarGraphics2.jpg"
							title="Cut Vinyl & Full Colour Car Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylFullColourCarGraphics3.jpg"
							title="Cut Vinyl & Full Colour Car Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylFullColourLorryGraphics.jpg"
							title="Cut Vinyl & Full Colour Lorry Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylFullColourSUVGraphics.jpg"
							title="Cut Vinyl & Full Colour SUV Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylFullColourVanGraphics.jpg"
							title="Cut Vinyl & Full Colour Van Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylFullColourVanGraphics2.jpg"
							title="Cut Vinyl & Full Colour Van Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylFullColourVanGraphics3.jpg"
							title="Cut Vinyl & Full Colour Van Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/CutVinylJCBGraphics.jpg"
							title="Cut Vinyl JCB Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/FullColourVanGraphics.jpg"
							title="Full Colour Van Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/ParticalFullColourVanWrap.jpg"
							title="Partical Full Colour Van Wrap"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/SmartCarFullColourWrap.jpg"
							title="Smart Car Full Colour Wrap"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/MATT_BLACK_CUT_WRAP_VINYL.jpg"
							title="MATT_BLACK_CUT_WRAP_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/FULL_COLOUR_PARTIAL_WRAP_AND_CONTOUR_CUT_LETTERS.jpg"
							title="FULL COLOUR PARTIAL WRAP AND CONTOUR CUT LETTERS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/FULL_COLOUR_&_GREEN_CUT_WRAP_VINYL_VEHICLE_GRAPHICS.jpg"
							title="FULL_COLOUR_&_GREEN_CUT_WRAP_VINYL_VEHICLE_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/VEHICLE_GRAPHICS_AND_CHEVRONS.jpg"
							title="VEHICLE_GRAPHICS_AND_CHEVRONS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/5-Vehicle-Graphics/FULL_COLOUR_PRINTED_VINYL_AND_CUT_WHITE_VINYL_SCOOTER_GRAPHICS.jpg"
							title="FULL_COLOUR_PRINTED_VINYL_AND_CUT_WHITE_VINYL_SCOOTER_GRAPHICS"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Let&rsquo;s face it: advertising is expensive. With upkeep, production and
									location fees, some forms of advertisement can be extremely costly. What&rsquo;s
									more, often it&rsquo;s not very effective. That&rsquo;s why we want to make
									marketing simple for you and bring your business to the customers, so they bring
									their business to you. We know what you&rsquo;re thinking, how can you achieve
									this with graphics that sit firmly within my budget? Well, that&rsquo;s where
									vehicle graphics come to play.
								</p>

								<p>
									Unlike your bog-standard form of display or sign, which sits statutory in one
									place with little reach to the public, vehicle graphics are a literal
									&lsquo;driving force&rsquo; of business and act as an amazing portable
									advertisement. If you&rsquo;re a business that utilises its vehicles and hasn't
									already wrestled with the idea of vehicle graphics, you&rsquo;re missing out.
								</p>

								<p>
									Whether you&rsquo;re returning to or from a job site or are stuck in a ridiculous
									traffic jam or just parked up on a side street, vehicle graphics and wraps are an
									on-the-go form of advertisement and make use of every minute of every day, 24/7,
									365. With graphics being an extremely malleable industry, we are able to find the
									perfect graphics e.g. vinyl graphics for your vehicle as they can fit onto almost
									any vehicle material and shape. Not only this, but we can tailor a solution to
									you: Looking to display contact information? Vinyl graphics can be popped onto
									your van or car to list all that important information pertinent to your business.
									Or maybe you are just looking to spice up your van without breaking the bank,
									vehicle wraps can be placed on the entirety of your vehicle exterior to
									personalise any vehicle of interest.
								</p>

								<p>
									What&rsquo;s more? Our vehicle wraps and graphics are long-lasting and waterproof
									meaning you have less maintenance to keep up with, so you can focus on all your
									new customers instead of the pesky replacements! To see some of our previous work,
									please feel free to visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									How can we help you? Our highly experienced team produce almost all of our final
									products in our Battersea HQ, meaning they will be able to produce an amazing
									result for you completely in-house with a quick turnaround. This also allows us to
									get your signage and graphics, especially concerning vehicles, anywhere in London,
									so you can get back to doing what you do best. What&rsquo;s more, since we are a
									London-based business we will be able to offer you our expert knowledge about what
									sells and what will work best for your business space.
								</p>

								<p>
									If you&rsquo;re interested in wrapping your vehicles with us &ndash; using
									graphics and signage &ndash; please contact one of our signage experts at 020 7350
									1567 or email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(VehicleGraphics);
