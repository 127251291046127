import React, { Component } from "react";
import PointerEvents from "../../fragments/common/PointerEvents";
import PageBannerA from "../../fragments/PageBannerA";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Title = styled.h3`
	color: var(--ys-teal);
	// padding-top:1rem;
`;

const Text = styled.p`
	padding-bottom: 2rem;
`;

class SignageSolution extends Component {
	componentDidMount() {
		PointerEvents();
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>A Complete Signage Solution in London - Yellow Signs</title>
					<meta
						name="description"
						content="We don't just sell signs - we offer a complete signage solution to ensure your business or organisation looks as good as it can! We work with a variety of clients in and around London"
					/>
				</Helmet>
				<PageBannerA
					line1="Signage"
					line2="Solution"
					img="./img/signagesolution/hero-truck.jpg"
					imgTiny="./img/signagesolution/hero-truck-tiny.jpg"
					width="22.4rem"
				/>
				<Container>
					<Row lg={3} style={{ marginTop: "4rem" }}>
						<Col sm={12}>
							<Title>SIGNAGE SOLUTION</Title>
							<Text>
								We don’t just sell signs, we offer a complete signage solution and help to build and
								enhance your brand. We pride ourselves on asking the right questions and starting
								the process in the most effective and efficient way to ensure each client receives
								the correct and most suitable solution. Our dedicated team and large range of
								products, services and equipment allow us to match the client to the solution each
								and every time.
							</Text>
						</Col>
						<Col sm={12}>
							<Title>SITE SURVEY</Title>
							<Text>
								Most signage jobs will require a site survey. This is an opportunity to measure up
								as well as discuss the client’s requirements and also uncover any additional
								requirements or limitations at the site that mayu exist. Site surveys are usually
								free of charge and it’s a great opportunity for us to learn about the job and the
								clients so are often essential to a successful project.
							</Text>
						</Col>
						<Col>
							<img
								src="./img/signagesolution/graphic1-v2.jpg"
								alt="Yellow Signs"
								style={{
									position: "absolute",
									height: "180%",
									right: "0",
								}}
							/>
						</Col>
						<Col sm={12}>
							<Title>JOB BRIEF</Title>
							<Text>
								A job brief often comes from the client or their in-house designer. It can also be
								worked through with our own designers and is often what the project work towards. We
								can help with this process or simple follow the brief supplied.
							</Text>
						</Col>
						<Col sm={12}>
							<Title>GRAPHIC DESIGN</Title>
							<Text>
								Our team of designers and production staff can help with everything from brief,
								concept, branding and graphic design. We can also help with the different types of
								promotion and advertising design, whether it be more layout and artwork or a larger
								more creative project.
							</Text>
						</Col>
					</Row>
					<Row style={{ marginTop: "2rem" }}>
						<img
							src="./img/signagesolution/graphic2-v2.jpg"
							alt="Yellow Signs"
							style={{
								width: "100%",
							}}
						/>
					</Row>
					<Row style={{ marginTop: "2rem" }}>
						<Col lg={4}>
							<img
								src="./img/signagesolution/graphic3-v2.jpg"
								alt="Yellow Signs"
								style={{
									position: "absolute",
									height: "318%",
									marginLeft: "-14px",
								}}
							/>
						</Col>
						<Col lg={4}>
							<Title>VISUALISATIONS</Title>
							<Text>
								Once the design work is signed off a visualisation or mock-up can be created to
								display the new design. This gives the client an opportunity to see it as close to
								real life before installation. Visualisations can be simple and effective from a
								photo or more in depth and detailed using 3D rendering tools.
							</Text>
						</Col>
						<Col lg={4}>
							<Title>SIGN MAINTENANCE</Title>
							<Text>
								Our team of installers and production staff understand that signage often needs to
								stand the test of time and even more temporary installs can require some form of
								maintenance to ensure the product is working properly and gives the client the best
								results possible. We have a range of products, tips and recommendations to ensure
								each product is utilities affectively.
							</Text>
						</Col>
						<Col lg={4}></Col>
						<Col lg={4}>
							<Title>INSTALLATION</Title>
							<Text>
								Professional and accurate installation is as important as the signage itself. With a
								wealth of experience our installers can advise on best materials when they visit the
								site. No site is the same and it often requires specialist knowledge to recommend
								the right product based on the materials and structures of the installation area.
							</Text>
						</Col>
						<Col lg={4}>
							<img
								src="./img/signagesolution/graphic4-v2.jpg"
								alt="Yellow Signs"
								style={{
									// width:"100%",
									position: "absolute",
									height: "251%",
									right: "0",
									marginTop: "-2rem",
								}}
							/>
						</Col>
						<Col lg={4}></Col>
						<Col lg={4}>
							<Title>PRODUCTION</Title>
							<Text>
								Our ability to print most signage products in-house allows us maximum control and a
								huge range of standard and bespoke products. Our large production centre in
								Battersea gives us maximum control over quality of fabrication and turnaround times.
								We router, lasercut, etch, engrave, print and 3D print in-house which allows us to
								offer a wide range of signage products and services. We also work with a range of
								tried, tested and trusted suppliers to further increase our product range.
							</Text>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default SignageSolution;
