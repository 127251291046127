import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class AcrylicLetters extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>3D Letters in London & Built Up Letters in London</title>
					<meta
						name="description"
						content="3D Letters for Businesses in London - We fabricate 3D built up letters in our Battersea production centre including illuminated 3D letters in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="3D & Built-Up Letters"
					subtitle="3d letters are made by building up materials such as acrylic or stainless steel. They can be faced in a wide range of materials and colours and can be illuminated with led lights if required. "
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/halo-illuminated-3d-built-up-letters.jpg"
							title="Halo Illuminated 3D Built Up Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO_ILLUMINATION.jpg"
							title="25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO_ILLUMINATION"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO__ILLUMINATION_-_WORKSHOP.jpg"
							title="25MM_WET_COATED_GOLD_3D_LETTERS_WITH_PINK_HALO__ILLUMINATION_-_WORKSHOP"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO.jpg"
							title="FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/WET_COATED_GOLD_HALO_ILLUMINATED_3D_LETTERS.jpg"
							title="WET_COATED_GOLD_HALO_ILLUMINATED_3D_LETTERS"
							alignment="left"
						/>

						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/25MM_WET_COATED_ACRYLIC_LETTERS_WITH_BLUE_HALO_ILLUMINATION.jpg"
							title="25MM_WET_COATED_ACRYLIC_LETTERS_WITH_BLUE_HALO_ILLUMINATION"
							alignment="left"
						/>
						{/* <ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/25MM_WET_COATED_ACRYLIC_LETTERS_WITH_BLUE_HALO_ILLUMINATION_-_WORKSHOP.jpg"
							title="25MM_WET_COATED_ACRYLIC_LETTERS_WITH_BLUE_HALO_ILLUMINATION_-_WORKSHOP"
						/> */}
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/FRONT_ILLUMINATION_3D_LETTERS_APPLIED_DIRECTLY_TO_OUTSIDE_OF_GLASS.jpg"
							title="FRONT ILLUMINATION 3D LETTERS APPLIED DIRECTLY TO OUTSIDE OF GLASS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/FRONT_ILLUMINATED_3D_BUILT-UP_LETTERS.jpg"
							title="FRONT ILLUMINATED 3D BUILT-UP LETTERS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/FlatFaceAluminiumLetterFaceOn.jpg"
							title="Flat Face Aluminium Letter Face On"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/FlatFaceAluminiumLetterCloseUp.jpg"
							title="Flat Face Aluminium Letter Close Up"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/FlatFaceIlluminatedLetterWhite.jpg"
							title="Flat Face Illuminated Letter White"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/FlatFaceStainlessSteelLetterCloseUp.jpg"
							title="Flat Face Stainless Steel Letter Close Up"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/FlatFaceStainlessSteelLetter.jpg"
							title="Flat Face Stainless Steel Letter"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/HaloIlluminatedLetterReverse.jpg"
							title="Halo Illuminated Letter Reverse"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/HaloIlluminatedLetter.jpg"
							title="Halo Illuminated Letter"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/InsertedFaceIlluminatedLetterCloseUp.jpg"
							title="Inserted Face Illuminated Letter Close Up"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/InserterFaceIlluminatedLetter.jpg"
							title="Inserter Face Illuminated Letter"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/MarqueeLightBulbArrow.jpg"
							title="Marquee Light Bulb Arrow.jpg"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/MarqueeLightBulbIlluminatedLetter.jpg"
							title="Marquee Light Bulb Illuminated Letter"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/MarqueeLightBulbIllumuninatedLetters.jpg"
							title="Marquee Light Bulb Illumuninated Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/MarqueeLightBulbLetter.jpg"
							title="Marquee Light Bulb Letter"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/RimFaceAluminiumLetter.jpg"
							title="Rim Face Aluminium Letter"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/RimFaceIlluminatedLetterCloseUp.jpg"
							title="Rim Face Illuminated Letter Close Up"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/RimFaceLetterCloseUp.jpg"
							title="Rim Face Letter Close Up"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/RimReturnLetterCloseUp.jpg"
							title="Rim & Return Letter Close Up"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/RimReturnPaintedLetter.jpg"
							title="Rim & Return Painted Letter"
						/>
						<ServicesGalleryImg
							img="/img/signselection/2-3D-Built-Up-Letters/RimReturnStainlessSteelLetter.jpg"
							title="Rim & Return Stainless Steel Letter"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									One of the most crucial steps of good business is making an impact on people.
									Whether it's via social media, an exhibition or in person, making your business
									stand out from the competition protects your business and increases your business.
									Whilst there are a variety of different options you could choose from; our
									favourite is hands down our 3D and built-up letter signage.
								</p>

								<p>
									3D signage both literally and metaphorically makes your business stand out on the
									Highstreet; since it stands off from its base and creates dimension, it creates a
									modern finish on your signage and is easy to see from greater distances achieving
									better brand visibility. What&rsquo;s more? Built-up lettering can be customised
									using a variety of materials, colours and shapes to suit all spaces, branding and
									budgets.
								</p>

								<p>
									So, what are some of your options? Well, to name a few, our team can produce a
									variety of different built-up types including flat letters which come in a range
									of materials including aluminium, stainless steel and acrylic. To create an extra
									impact, you can even go as far as illuminating your lettering to &lsquo;shine a
									spotlight' on your business and draw in new customers. Built-up signage is the
									perfect overhead, outdoor signage, so much so that we want to offer you the
									ability to choose the application of your product, including the fixings we use on
									the signage to align with your budget requirements.
								</p>

								<p>
									To see some of our past work, please visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
									<br />
									So aside from brand visibility, what makes built-up letters such a fantastic
									choice for your business? Built-up signage is a rare opportunity to implement the
									fun or creative elements of your business in a manner that keeps within your
									professionalism. Maybe you&rsquo;re a brand-new coffee shop, and built-up letters
									can be applied by using a personalised colour palette on an interesting backing to
									shed light on a small business. Or maybe you&rsquo;re opening a new branch of a
									well-known chain and want to make it known to your customers. Whatever it may be,
									we want to assure you there are plenty of diverse alternatives to highlight your
									business.
								</p>

								<p>
									Where do we come in? Built-up and 3D lettering is one of our most popular options
									and since we are a London-based business and produce almost all of our bespoke
									products in our Battersea HQ, it means we can complete a quick turnaround without
									outsourcing different products from different locations. This allows you to
									receive your signage or graphics, especially built-up signage, in London, anywhere
									in London. If you&rsquo;re struggling for inspiration and would like some help
									producing something unique and exciting, feel free to contact us; our experienced
									team are more than happy to help you.
								</p>

								<p>
									If you&rsquo;re interested in our services &ndash; using graphics and signage
									&ndash; please contact one of our signage experts on 020 7350 1567 or email{" "}
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(AcrylicLetters);
