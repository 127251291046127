import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class FullColourGraphics extends Component {
	componentDidMount() {
		PointerEvents();
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Full Colour Graphics in London, Signage Specialist in London</title>
					<meta
						name="description"
						content="Full Colour Printed Graphics - We offer super-fast turnaround on printed graphics as we use UV cured ink which allows for same-day supply and install in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Full Colour Graphics"
					subtitle="FULL COLOUR GRAPHICS ARE IDEAL FOR RETAIL SPACES OR EXHIBITIONS AND CAN BE PRINTED IN ALMOST ANY SIZE USING SOLVENT AND SPOT UV INK PLUS OPTIONAL LAMINATION FOR EXTRA DURABILITY. tHERE IS A LARGER RANGE OF COLOURS AVAILABLE COMPARED TO CUT VINYL."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/5mm-acrylic-letters--full-colour-window-graphics.jpg"
							title="5mm Acrylic Letters & Full Colour Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/contour-cut-full-colour-digitall-printed-window-graphics.jpg"
							title="Contour Cut Full Colour Digital Printed Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/fridge-vinyl-wrap.jpg"
							title="Fridge Vinyl Wrap"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/full-colour-digitally-printed-lightbox-menus.jpg"
							title="Full Colour Digitally Printed Lightbox Menus"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/full-colour-digitally-printed-vinyl.jpg"
							title="Full Colour Digitally Printed Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/full-colour-digitally-printed-window-graphics-1.jpg"
							title="Full Colour Digitally Printed Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/full-colour-digitally-printed-window-graphics-2.jpg"
							title="Full Colour Digitally Printed Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/full-colour-digitally-printed-window-graphics.jpg"
							title="Full Colour Digitally Printed Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/FullColourAluminiumComposite.jpg"
							title="Full Colour Aluminium Composite"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/FullColourGraphicsFrostedPrivacyVinyl.jpg"
							title="Full Colour Graphics & Frosted Privacy Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics.jpg"
							title="Full Colour Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics2.jpg"
							title="Full Colour Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics3.jpg"
							title="Full Colour Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics4.jpg"
							title="Full Colour Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics5.jpg"
							title="Full Colour Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/FullColourWindowGraphics7.jpg"
							title="Full Colour Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/WrappedFullColourVinyl.jpg"
							title="Wrapped Full Colour Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/8-Full-Colour-Graphics/FULL_COLOUR_WINDOW_GRAPHICS.jpg"
							title="FULL COLOUR WINDOW GRAPHICS"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Aesthetics are essentially the bread and butter of the visual marketing industry.
									It takes ordinary people and captures their attention through colour, design and
									concepts. As business leaders and workers, possibly considering dipping into your
									visual advertisement game, you should carefully consider how colour plays a role
									in your agenda. We use colour online on our websites and logos, but how can we
									flip this into daily life: and better yet how can you enhance your graphics game
									in one easy step? Three words: full colour graphics.
								</p>

								<p>
									What are they and how can you use these to influence your consumer behaviour? Full
									colour graphics really are as simple as it sounds; it&rsquo;s a mix of the four
									main printing colours to produce a vibrant, eye catching and high-quality finish
									that can be applied to a variety of different forms of signage and graphics. Maybe
									you&rsquo;re looking to create an indoor-outdoor experience and think some new
									window graphics might spice up your exterior, or perhaps you&rsquo;ve just bought
									some overhead counter menus for your brand-new caf&eacute; and want to make your
									customers to judge the book by its cover. Whatever your reason is, full colour
									graphics are a great way to produce a quality and professional results you
									can&rsquo;t receive from your average printing or application service.
								</p>

								<p>
									One of our most popular materials we use for full colour graphics is vinyl as it
									has plenty of colour diversity &ndash; more colour variety than cut vinyl - and
									can be used in a variety of different ways to highlight your business. A great
									option to personalise a brands products or vehicles is vinyl wrapping which is a
									great way to help conventional of basic marketing products to align with your
									companies brand identity. Not only this, but full colour graphics on vinyl is
									incredible cost effective unlike some advertisement options which is a great
									alternative to some of the more price intensive options.
								</p>

								<p>
									To see some of our past work, feel free to check out:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									So how can we help you? Our team here at Yellow Signs produce almost all of
									everything we do, including full colour graphics, in our Battersea headquarters.
									As a London based business, we know that prices can be steep so we want to assure
									you that we offer competitive prices that don&rsquo;t compromise on the integrity
									of your designs. Since we are so local, it also means we can provide your signage
									and graphics, like full colour graphics, to you in London!
								</p>

								<p>
									If you&rsquo;re interested in decking out your event with us &ndash; using
									graphics and signage &ndash; please contact one of our signage experts on 020 7350
									1567 or email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(FullColourGraphics);
