import React, { Component } from "react";
import HeaderSimple from "../fragments/services/HeaderSimple";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet";
// import styled from 'styled-components';

class PrivacyPolicy extends Component {
	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Privacy Policy - Yellow Signs, Battersea London</title>
					<meta
						name="description"
						content="You can read our privacy policy here for Yellow Signs - any questions, just ask!"
					/>
				</Helmet>
				<HeaderSimple heading="Privacy Policy" />
				<Container>
					<Row>
						<Col>
							<p>
								<strong>Data Collection</strong>
							</p>
							<p>
								This page informs you of our policies regarding the collection, use and disclosure
								of Personal Information we receive from users of the site. We use your personal
								information only for providing and improving the site. By using the site, you agree
								to the collection and use of information in accordance with this policy
							</p>
							<p>
								Information collection and use while using our site, we may ask you to provide us
								with certain personally identifiable information that can be used to contact or
								identify you. Personally identifiable information may include, but is not limited to
								your name.
							</p>
							<p>
								<strong>Log Data</strong>
							</p>
							<p>
								Like many site operators, we collect information that your browser sends whenever
								you visit our site (Log Data). This log data may include information such as your
								computer's Internet Protocol (IP) address, browser type, browser version, the pages
								of our site that you visit, the time and date of your visit, the time spent on those
								pages and other statistics. In addition, we may use third party services such as
								Google Analytics that collect, monitor and analyse this.
							</p>
							<p>
								<strong>Cookies</strong>
							</p>
							<p>
								Cookies are files with small amount of data, which may include an anonymous unique
								identifier. Cookies are sent to your browser from a web site and stored on your
								computer's hard drive. Like many sites, we use "cookies" to collect information. You
								can instruct your browser to refuse all cookies or to indicate when a cookie is
								being sent. However, if you do not accept cookies, you may not be able to use some
								portions of our Site.
							</p>
							<p>
								<strong>Changes to this Privacy Policy</strong>
							</p>
							<p>
								This Privacy Policy is effective as of 1st November 2020 and will remain in effect
								except with respect to any changes in its provisions in the future, which will be in
								effect immediately after being posted on this page. We reserve the right to update
								or change our Privacy Policy at any time and you should check this privacy policy
								periodically. Your continued use of the service after we post any modifications to
								the privacy policy on this page will constitute your acknowledgement of the
								modifications and your consent to abide and be bound by the modified privacy policy.
								If we make any material changes to this privacy policy, we will notify you either
								through the email address you have provided us, or by placing a prominent notice on
								our website.
							</p>
							<p>
								<strong>Contact Us</strong>
							</p>
							<p>
								If you have any questions about this privacy policy, please contact us –{" "}
								<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default PrivacyPolicy;
