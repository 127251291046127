import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class ProjectingSigns extends Component {
	componentDidMount() {
		PointerEvents();
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Projecting Signs in London, Signage Specialist in Battersea</title>
					<meta
						name="description"
						content="Projecting Signs for Businesses in London - We offer a wide variety of projecting signs for shops and offices in London, including illuminated projecting signs"
					/>
				</Helmet>
				<HeaderSimple
					heading="Projecting Signs"
					subtitle="These signs are DESIGNED TO PROJECT OR PROTRUDE FROM A FASCIA, THEY ALLOW POTENTIAL CUSTOMERS TO SEE THE BUSINESS FROM A DISTANCE. AVAILABLE IN RECTANGULAR, CIRCULAR OR BESPOKE SHAPES. Projecting signs can often have more impact than the main sign, so great value."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/AluminiumCopperAcrylicBackedIlluminated.jpg"
							title="Aluminium & Copper Acrylic Backed Illuminated"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/AluminiumLightbox.jpg"
							title="Aluminium Lightbox"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/AluminiumLightbox2.jpg"
							title="Aluminium Lightbox"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/AluminiumLightboxCutMirrorGoldVinyl.jpg"
							title="Aluminium Lightbox & Cut Mirror Gold Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/CircularWoodPushedThroughAcrylicIlluminated.jpg"
							title="Circular Wood & Pushed-Through Acrylic Illuminated"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/CustomAluminiumCutVinyl.jpg"
							title="Custom Aluminium Cut Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/CustomIlluminatedAluminiumAcrylic.jpg"
							title="Custom Illuminated Aluminium & Acrylic"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/DoubleSidedTroughLitProjectingSign.jpg"
							title="Double Sided Trough Lit Projecting Sign"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/FoamPVCCutMirrorGoldVinylSwing.jpg"
							title="Foam PVC & Cut Mirror Gold Vinyl Swing"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/FoamPVCSwing.jpg"
							title="Foam PVC Swing"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/FoamPVCSwing2.jpg"
							title="Foam PVC Swing"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/FoamPVCSwing3.jpg"
							title="Foam PVC Swing"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/StainlessSteelAcrylicIlluminated.jpg"
							title="Stainless Steel & Acrylic Illuminated"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINUM_TRAY_&_PROJECTING_SIGN_WITH_FRONT_ILLUMINATED_3D_LETTERS.jpg"
							title="POWDER_COATED_ALUMINUM_TRAY_&_PROJECTING_SIGN_WITH_FRONT_ILLUMINATED_3D_LETTERS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/FUL_COLOUR_PRINTED_&_WRAPPED_25MM_FOAM_PVC_SWING_PROJECTING_SIGN.jpg"
							title="FUL_COLOUR_PRINTED_&_WRAPPED_25MM_FOAM_PVC_SWING_PROJECTING_SIGN"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINIUM_CIRCULAR_PROJECTING_SIGN_WITH_GOLD_WET_COATED_ACRYLIC_LETTERS.jpg"
							title="POWDER_COATED_ALUMINIUM_CIRCULAR_PROJECTING_SIGN_WITH_GOLD_WET_COATED_ACRYLIC_LETTERS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_WET_COATED_5MM_ACRYLIC_LOGO.jpg"
							title="OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_WET_COATED_5MM_ACRYLIC_LOGO"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_BLACK_CUT_VINYL_LOGO.jpg"
							title="OPAL_ACRYLIC_LIGHTBOX_PROJECTING_SIGN_WITH_BLACK_CUT_VINYL_LOGO"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINIUM_LIGHTBOX_PROJECTING_SIGN.jpg"
							title="POWDER_COATED_ALUMINIUM_LIGHTBOX_PROJECTING_SIGN"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/POWDER_COATED_ALUMINIUM_PROJECTING_SIGN_WITH_5MM_WET_COATED_LETTERS_AND_DOWN-LIGHTER.jpg"
							title="POWDER_COATED_ALUMINIUM_PROJECTING_SIGN_WITH_5MM_WET_COATED_LETTERS_AND_DOWN-LIGHTER"
						/>
						<ServicesGalleryImg
							img="/img/signselection/4-Projecting-Signs/POWDER_COATED_PROJECTING_SIGN_WITH_DOUBLE_SIDED_TROUGH_LIGHTS.jpg"
							title="POWDER_COATED_PROJECTING_SIGN_WITH_DOUBLE_SIDED_TROUGH_LIGHTS"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Sometimes regular overhead signage just doesn&rsquo;t cut it. Perhaps your shop is
									surrounded by crowds of other similar businesses and the sign option are too
									saturated and difficult to view, or maybe you&rsquo;re based in a hard-to-notice
									corner of the high street and are struggling to drum up or direct your customer's
									attention. It can be a pain to invest money into your externals for them not to be
									appreciated. We think that&rsquo;s a massive shame and we want to tell you a bit
									about projected signs and how they can attract customers from more angles
									regardless of your positioning on the high street.
								</p>

								<p>
									Firstly, what are projected signs? Projected signs are signboards that are
									produced from a fascia with your shop or business name. They can be bespoke and
									designed to align with pre-existing logos or brand identities or even new logos
									you might be considering; varying in colour, size and even material to perfect the
									exact look and budget requirements you have set for your signage. Here at Yellow
									Signs, we produce almost all of our work in-house at our Battersea headquarters so
									we can produce and install signage and graphics such as projecting signs all over
									London.
								</p>

								<p>
									Why do projected signs benefit businesses? Unlike your traditional shop or retail
									sign that sits overhead and faces one direction, Projected signs are multifaceted
									and allow you to attract customers from a greater deal of angles and a much
									further distance offering more consumer potential and even working better than
									your main signage at times. What&rsquo;s more, they are highly customisable and
									work as a 24/7 marketing tool for your business. We offer a range of options
									including your traditional round and circular as well as more bespoke shapes for
									those who want to try something more interesting with their design.
								</p>

								<p>
									For examples of our past works, you can check out{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									Let&rsquo;s check out some of your options! A popular and cost-effective option we
									offer is foam swinging PVC boards, with plenty of design choices, these are a
									great way to enhance your business however we understand some brands like to take
									things a step or two further and one of our most popular forms of projected
									signage does just that; our lightboxes and illuminated tray projected signs are
									striking and illuminate your customers about your business which is sure to turn
									heads! With lots of colour options and coatings to choose from, make your business
									the perfect shopper experience from start to finish.
								</p>

								<p>
									If you&rsquo;re interested in building your business with Yellow Signs &ndash;
									using graphics and signage &ndash; please contact one of our signage experts at
									020 7350 1567 or email{" "}
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(ProjectingSigns);
