import React, { Component } from "react";
import Helmet from "react-helmet";
import PageBannerA from "../../fragments/PageBannerA";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";

import QuoteForm from "../../fragments/QuoteForm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Title = styled.h3`
	color: var(--ys-teal);
`;

const Text = styled.p``;

const Accent = styled.span`
	font-weight: 600;
	color: var(--ys-teal);
`;

const WhatsappImgGreen = styled.img`
	height: 1.1rem;
	margin-right: 0.25rem;
`;

const socialImgWhatsapp = {
	color: "var(--ys-teal)",
	width: "1rem",
	height: "1rem",
};

const MapWithMarker = ({ text }) => (
	<img src="./img/contactus/y-pin.png" alt="Yellow Signs" style={{ width: "3.8rem" }}></img>
);

class ContactUs extends Component {
	componentDidMount() {
		window.scroll(0, 0);
	}

	static defaultProps = {
		center: {
			lat: 51.4659139,
			lng: -0.1829282,
		},
		zoom: 14,
	};

	render() {
		return (
			<div>
				<Helmet>
					<title>Contact Us - Yellow Signs - Signs & Graphics in London</title>
					<meta
						name="description"
						content="Contact us by phone, email, Whatsapp or Chat - or pop in to our Battersea headquarters for a chat about your next project - we're here to help!"
					/>
				</Helmet>

				<PageBannerA
					line1="Get in"
					line2="Touch"
					img="./img/contactus/map.jpg"
					imgTiny="./img/contactus/map-tiny.jpg"
					width="23.5rem"
				/>
				<Container
					style={{
						margin: "6rem auto",
					}}
				>
					<Row>
						<Col lg={8}>
							<img
								src="./img/contactus/ys-building.jpg"
								style={{ width: "85%" }}
								alt="Yellow Signs Building"
							/>
						</Col>
						<Col lg={4} style={{ marginTop: "5rem" }}>
							<Title>HQ & PRODUCTION CENTRE</Title>
							<Text>
								Unit 5, River Reach,
								<br />
								Gartons Way,
								<br />
								Battersea,
								<br />
								London SW11 3SX
							</Text>
							<Text>
								<Accent>t:</Accent> <a href="tel:02073501567">020 7350 1567</a>
							</Text>
							<Text>
								<WhatsappImgGreen src="./img/common/whatsapp.svg" />
								<a href="https://wa.me/4407539269999" id="email">
									{" "}
									075 3926 9999{" "}
								</a>
								(Whatsapp Photos)
							</Text>
							<Text>
								<Accent>e:</Accent>{" "}
								<a href="mailto:info@yellowsigns.co.uk" id="email">
									info@yellowsigns.co.uk
								</a>
							</Text>
							<Text>
								<Accent>Opening Times</Accent>
							</Text>
							<Row>
								<Col lg={5}>
									<Text>
										Monday - Friday
										<br />
										Saturday:
										<br />
										Sunday:
									</Text>
								</Col>
								<Col lg={5}>
									<Text>
										8am – 5pm
										<br />
										Closed
										<br />
										Closed
									</Text>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>

				<QuoteForm />

				<Container>
					<Row>
						<Col>
							<div style={{ height: "30rem", width: "100%" }}>
								<GoogleMapReact
									bootstrapURLKeys={{
										key: "AIzaSyDBCcJPr94ThJjBv5Hnwf63p5PU9cDLFkI",
									}}
									defaultCenter={this.props.center}
									defaultZoom={this.props.zoom}
								>
									<MapWithMarker lat={51.465985} lng={-0.1809263} />
								</GoogleMapReact>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default ContactUs;
