import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class CutVinylGraphics extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Hoarding Signs in London, Signage Specialist in Battersea</title>
					<meta
						name="description"
						content="Hoarding for Building or Construction Sites in London - We offer 3mm aluminium composite full colour printed or cut vinyl hoarding for businesses in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Hoarding"
					subtitle="Hoarding is the perfect advertising opportunity for any building site or construction project. It usually consists of 1220mm x 2440mm aluminium composite boards with either full colour print or cut vinyl applied to the face."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/Hoarding/FULL_COLOUR__CUT_VINYL_ALUMINIUM_COMPOSITE_HOARDING.jpg"
							title="FULL_COLOUR_&_CUT_VINYL_ALUMINIUM_COMPOSITE_HOARDING"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Hoarding/FULL_COLOUR_ALUMINIUM_COMPOSITE_ANTI-GRAFFITI_HOARDING.jpg"
							title="FULL_COLOUR_ALUMINIUM_COMPOSITE_ANTI-GRAFFITI_HOARDING"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Hoarding/FULL_COLOUR_ALUMINIUM_COMPOSITE_DIRECTIONAL_PANELS.jpg"
							title="FULL_COLOUR_ALUMINIUM_COMPOSITE_DIRECTIONAL_PANELS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Hoarding/FULL_COLOUR_ALUMINIUM_COMPOSITE_HOARDING_WITH_MATCHING_KICK-STANDS.jpg"
							title="FULL_COLOUR_ALUMINIUM_COMPOSITE_HOARDING_WITH_MATCHING_KICK-STANDS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Hoarding/FULL_COLOUR_ALUMINIUM_COMPOSITE_HOARDING.jpg"
							title="FULL_COLOUR_ALUMINIUM_COMPOSITE_INTERNAL_HOARDING"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Hoarding/FULL_COLOUR_ALUMINIUM_COMPOSITE_INTERNAL_HOARDING.jpg"
							title="FULL_COLOUR_ALUMINIUM_COMPOSITE_INTERNAL_HOARDING"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Hoarding finishes are available in matt, satin or gloss with or without lamination
									- we also offer anti-graffiti lamination for extra protection.
								</p>

								<p>
									Hoarding can be simple full sheets or it can be more bespoke. Smaller projects
									usually include more intricate shapes as well as cut-out for letterboxes, locks
									and doors – something that our highly skilled production and installation team are
									all too familiar with.
								</p>

								<p>
									High-end residential refurbishments and construction projects are a perfect way to
									protect the site and advertise the various parties involved in the project, such
									as the contractor, sub-contractors, architects, developers and designers. It’s
									quite common for all parties to share the cost of the hoarding, making it an
									extremely good value form of advertising and brand reinforcement.
								</p>

								<p>
									For examples of our past works, you can check out{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									If you’re interested in hoarding for your business please contact one of our
									signage experts at 020 7350 1567 or email{" "}
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>
								<p>
									Alternatively, to request a quick quote visit{" "}
									<a href="https://yellowsigns.co.uk/quote-site-visit">
										yellowsigns.co.uk/quote-site-visit
									</a>{" "}
									to get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(CutVinylGraphics);
