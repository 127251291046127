import React, { Component } from 'react';
import PointerEvents from '../fragments/common/PointerEvents'
import PageBannerA from '../fragments/PageBannerA'

class SignBuyersGuide extends Component {

  componentDidMount() {
    PointerEvents();
  }

  render() {
    return (
      <div>
        <PageBannerA 
          line1="Sign Buyers"
          line2="Guides"
          img="./img/banners/sign-buyers-guide.jpg"
          width="22.4rem"
        />
      </div>
    );
  }
}

export default SignBuyersGuide;