import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class SchoolEducation extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Signs for Schools, Colleges and Universities in London</title>
					<meta
						name="description"
						content="Schools, colleges and universities in London can benefit from new signage to reinforce their branding as well as wayfinding for students and teachers, internally and externally."
					/>
				</Helmet>
				<HeaderSimple
					heading="School & Education"
					subtitle="Primary schools, secondary schools, colleges and universities often use a wide range of internal and external signage – from floor decals and wall graphics to outside post sides and PVC banners. PTAs often use banners and boards for events such as school fairs and fetes."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signsbysector/7-School-Education/full-colour-digitally-printed-vinyl.jpg"
							title="Full Colour Digitally Printed Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/7-School-Education/AluminiumCompositeCutVinylSign.jpg"
							title="Aluminium Composite Cut Vinyl Sign"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/7-School-Education/AluminiumPostSigns.jpg"
							title="Aluminium Post Signs"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/7-School-Education/AluminiumPostSigns2.jpg"
							title="Aluminium Post Signs"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/7-School-Education/BuiltUpStainlessSteelPostSign.jpg"
							title="Built-Up Stainless Steel Post Sign"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/7-School-Education/FullColourAluminiumCompositeGraphics.jpg"
							title="Full Colour Aluminium Composite Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/7-School-Education/StainlessSteelFaceLettersonTrayPostSign.jpg"
							title="Stainless Steel Face Letters on Tray Post Sign"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/7-School-Education/ALUMINIUM_COMPOSITE_SIGN_TRAYS_WITH_FULL_COLOUR_PRINT.jpg"
							title="ALUMINIUM COMPOSITE SIGN TRAYS WITH FULL COLOUR PRINT"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/7-School-Education/FULL_COLOUR_PVC_BANNER_SUSPENSION_SYSTEMS.jpg"
							title="FULL COLOUR PVC BANNER SUSPENSION SYSTEMS"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									With all the uncertainty in the world, the one thing we can rely on as a stable
									institution in our society is education. We learn every day through the people we
									interact with, the books and articles we read; even the posts we see on Twitter.
									We are constantly learning and growing. At school, we get our first look at the
									world around us; from English to Geography we enter a building ready to inspire
									and teach. But how can we make those 4 walls of a classroom a teachable and
									inspirational moment for this generation of students; better yet, how can we do
									this using graphics and signage?
								</p>

								<p>
									Regardless of what educational site you&rsquo;re looking to redesign, every
									school, college and university need signage to direct and help navigate students
									to their required rooms or study spaces. Whether you seek an aluminium composite
									cut vinyl sign to point you in the direction of the science block or built-up
									letters to label your canteen, signage is vital to ensure your students aren't
									left wandering in the hallways and are where they need to be. Especially as
									developing minds enter the school sector, the need for clarity and easy-to-read
									signage grows yearly. However, your large-scale signage isn&rsquo;t your only
									option for directional signage. Wall and floor decals and graphics can provide the
									perfect tool to point guests in the right direction for open days or to maintain
									distance after post-covid restrictions as they are easily removable and
									non-damaging.
								</p>

								<p>
									However, signage doesn&rsquo;t always need to be for the important details, it can
									also be used to spice up the halls of a school or nursery and provide a fantastic
									entertaining or motivational outlet for students. When students open up their
									books for their examinations and the heat begins to rise, cut-vinyl graphics
									denoting positive quotes are a great way to encourage and calm down worried
									students in stressful moments of their academic careers. For a library, surrounded
									by exciting fiction or mythological tales, transport your students inside the
									books they read with bespoke digital wallpaper to immerse them in their learning.
								</p>

								<p>
									To view some of our past work, visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									At Yellow Signs, we want to be a part of making education and schools thrive. As a
									London-based business, we have supplied plenty of signage and graphics for schools
									in London, so we know just how the environment works. We also understand that with
									term times and schools being incredibly busy, finding that perfect timeframe to
									produce and install signage is key to prevent any disruption; we produce almost
									everything we do in our Battersea workshop to get your products completed quickly
									and installed! If you need any help designing or require a bespoke service, feel
									free to contact our team, who will be happy to help you.
								</p>

								<p>
									If you&rsquo;re interested in working with us &ndash; using graphics and signage
									&ndash; please contact one of our signage experts at 020 7350 1567 or email
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(SchoolEducation);
