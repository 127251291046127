import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class CutVinylGraphics extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>In-House Bespoke Fabrication of Signs in London</title>
					<meta
						name="description"
						content="Bespoke Fabrication - We can also make custom products with our large range of machines and equipment in our Battersea production centre in South West London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Bespoke Fabrication"
					subtitle="Do you need some signage that you can’t see on our website? Chances are we still do it! We specialise in fabricating our signs and graphics in-house from our Battersea production centre and have a large list of machines which means we can fabricate almost anything you can think of. "
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/Bespoke-Fabrication/4-COLOUR_3D_PRINTING.jpg"
							title="4-COLOUR_3D_PRINTING"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Bespoke-Fabrication/ACRYLIC_BILTONG_AIR_DRYER.jpg"
							title="ACRYLIC_BILTONG_AIR_DRYER"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Bespoke-Fabrication/ARTIFICIAL_GRASS__ACRYLIC.jpg"
							title="ARTIFICIAL_GRASS_&_ACRYLIC"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Bespoke-Fabrication/BESPOKE_HALO_LED_ACRYLIC.jpg"
							title="BESPOKE_HALO_LED_ACRYLIC"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Bespoke-Fabrication/CUSTOMISED_LED_DOWNLIGHERS.jpg"
							title="CUSTOMISED_LED_DOWNLIGHERS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Bespoke-Fabrication/FAUX_NEON_ROUNDEL.jpg"
							title="FAUX_NEON_ROUNDEL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Bespoke-Fabrication/HOSPITALITY_HIDE.jpg"
							title="HOSPITALITY_HIDE"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Bespoke-Fabrication/SPEAKER_WRAPPING.jpg"
							title="SPEAKER_WRAPPING"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Bespoke-Fabrication/SUSPENDED_CEILING_LED_NEON.jpg"
							title="SUSPENDED_CEILING_LED_NEON"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Through a combination of machinery and professional know-how, our team of experts
									can help you realise your next project idea.
								</p>

								<p>
									As well as traditional print, graphics and signage equipment we also utilise the
									latest 3D printing technology including eco-friendly consumables. 3D Printing is a
									useful fabrication tool which we often use in conjunction with other production
									methods and machinery for more bespoke projects and solutions.
								</p>

								<p>
									PET-G is the most common filament used in 3D printing. It’s extremely hard wearing
									and suitable for outdoor use – it’s virtually indestructible.
								</p>
								<p>
									PLA is a more eco-friendly and sustainable product. It is a recyclable, natural
									thermoplastic polyester that is derived from renewable resources such as corn
									starch or sugar cane. It’s still very hard wearing but we recommend it for indoor
									use only.
								</p>

								<p>
									For examples of our past works, you can check out{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									If you’re interested in bespoke fabrication or 3D printing for your business
									please contact one of our signage experts at 020 7350 1567 or email{" "}
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>
								<p>
									Alternatively, to request a quick quote visit{" "}
									<a href="https://yellowsigns.co.uk/quote-site-visit">
										yellowsigns.co.uk/quote-site-visit
									</a>{" "}
									to get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(CutVinylGraphics);
