import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../fragments/TestimonialsSlider.css";
import styled from "styled-components";
import device from "./common/device";

const SingleTestimonial = styled.div`
	height: 16rem;
	padding: 1.5rem;
	@media ${device.md} {
		height: 18rem;
	}
`;

const Txt = styled.p`
	color: black;
	padding: 1.25rem 0.25rem 0 0.25rem;
	font-style: italic;
	margin-top: 1rem;
	line-height: 1.5;
`;

const Title = styled.h4`
	padding: 0.25rem 0.25rem 1.25rem 0.25rem;
	padding-left: 1rem;
	@media ${device.md} {
		font-size: 0.75rem !important;
		padding: 0.25rem 0.25rem 1.25rem 0rem;
	}
`;

const QuoteIcon = styled.img`
	position: absolute;
	margin: -5rem 0px 0px 7rem;
	width: 6rem;
	height: 6rem;
	@media ${device.md} {
		margin: -5rem 0px 0px 4.6rem;
	}
`;

class TestimonialsSlider extends Component {
	render() {
		var settings = {
			autoplay: true,
			dots: true,
			infinite: true,
			speed: 800,
			slidesToShow: 3,
			slidesToScroll: 1,
			centerMode: true,
			responsive: [
				{
					breakpoint: device.mid,
					settings: {
						slidesToShow: 1,
						infinite: true,
						dots: true,
					},
				},
			],
		};

		const testimonialData = [
			[
				{ title: "Rhubarb Food Design / Sky Garden" },
				{
					text: "We choose Yellow for their flexibility, consistently high‑quality production and reliability as a supplier to our business. Due to the fast-pace of our business, they adapt to our tight‑deadlines, going over and above to meet quick print runs across a wide breath of products.",
				},
			],
			[
				{ title: "Tinseltown Restaurants" },
				{
					text: "Yellow Signs were instrumental in pre site meetings and provide detailed measurements. Tinseltown thoroughly recommends Yellow Signs and we have already contracted them for our next two projects.",
				},
			],
			[
				{ title: "Max Evans" },
				{
					text: "Amazing service from these guys. They are incredibly creative while also being calm and responsive to all the tweaks and changes I wanted to make. They did the vinyl sticker on my company van which looks incredible and I have already enlisted their services again for my shop front. Would recommend without hesitation!",
				},
			],
			[
				{ title: "Rebecca Brown" },
				{
					text: "Yellow Signs always do a brilliant job, I have used them on many occasions for installing vinyl graphics to large hotel/shop window fronts. They are quick to reply with a quote, and even quicker to create any artwork and install the signage. Reliable company, recommended!",
				},
			],
			[
				{ title: "Property People" },
				{
					text: "The team at Yellow Signs have been great. Thanks for coming up with this so quickly - the quality and speed of install was really impressive. We now need our cars wrapped!",
				},
			],
		];

		const ListTestimonials = testimonialData.map((item) => (
			<div key={item[0].title}>
				<div
					style={{
						height: "4rem",
						background: "white",
					}}
				></div>
				<SingleTestimonial>
					<QuoteIcon src={"./img/common/quotes.png"} alt="quote-mark" className="faQuote" />
					<Txt className="topBorder">{item[1].text}</Txt>
					<Title className="bottomBorder">{item[0].title}</Title>
				</SingleTestimonial>
			</div>
		));

		return (
			<div
				style={{
					paddingBottom: "3rem",
					width: "114%",
					marginLeft: "-7%",
				}}
				id="testimonials-slider"
			>
				<Slider {...settings}>{ListTestimonials}</Slider>
			</div>
		);
	}
}

export default TestimonialsSlider;
