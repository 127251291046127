import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class CutVinylGraphics extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Digital & Litho Printing with In-House Production London</title>
					<meta
						name="description"
						content="Digital & Litho Print - Our sister company Yellow Print offers a wide range of printed products, including business cards, flyers and booklets for businesses in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="DIGITAL & LITHO PRINT"
					subtitle="Yellow Print has been delivering the best in digital and litho print for nearly 20 years, with a core customer base in London but also customers all over the UK."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/Print/booklets-brochures.jpg"
							title="booklets brochures"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Print/business-stationery.jpg"
							title="business stationery"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Print/document-printing.jpg"
							title="document printing"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Print/embossing-and-foiling.jpg"
							title="embossing and foiling"
						/>
						<ServicesGalleryImg img="/img/signselection/Print/flyers.jpg" title="flyers" />
						<ServicesGalleryImg
							img="/img/signselection/Print/folded-leaflets.jpg"
							title="folded leaflets"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Print/graphics-and-vinyl.jpg"
							title="graphics and vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Print/labels-and-stickers.jpg"
							title="labels and stickers"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Print/occasional-print.jpg"
							title="occasional print"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Print/posters-and-plans.jpg"
							title="posters and plans"
						/>
						<ServicesGalleryImg img="/img/signselection/Print/signage.jpg" title="signage" />
						<ServicesGalleryImg
							img="/img/signselection/Print/wedding-stationery.jpg"
							title="wedding stationery"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Yellow Print offers same-day delivery in London, as well as next-day delivery to
									anywhere in the UK.
								</p>

								<p>
									You can also collect your print job from the production centre in the same
									building as Yellow Signs, at Unit 5B River Reach, Gartons Way, London SW11 3SX.
								</p>

								<p>
									Yellow Print offers a complete print solution for any business – whether it be an
									SME, non-profit or large corporate company. Products range from short run digital
									print such as flyers and business cards, to long run leaflets and booklets, as
									well as large format print and bespoke printing.
								</p>

								<p>
									Bespoke print includes embossing, debossing, foiling, colour edging and various
									laminating options so you can always find the right product and solution for your
									business.
								</p>
								<p>
									Yellow Print holds a huge range of papers and cards including premium ranges
									including GF Smith for those extra special and more luxurious print jobs where you
									need to make a statement or standout from the crowd. Feel free to pop into the
									production centre to look at samples – just contact us before you pop in to make
									sure we have the right samples ready for you.
								</p>

								<p>
									For more information and to see examples of print from our sister company, please
									visit <a href="https://yellowprint.co.uk ">www.yellowprint.co.uk </a>
								</p>

								<p>
									Alternatively, if you would like to place an order for print or would like some
									advice, please contact one of our print experts on 020 8875 0332 or email{" "}
									<a href="mailto:info@yellowprint.co.uk">info@yellowprint.co.uk</a>
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(CutVinylGraphics);
