import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class GymsFitness extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Signs & Graphics for Gyms & Fitness Studios in London</title>
					<meta
						name="description"
						content="Gyms and fitness studios can benefit from new internal and external signs and graphics to encourage new members and keep current members motivated in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Gyms & Fitness"
					subtitle="Gyms and fitness studios often require a wide range of internal and external signage, including floor decals, wayfinding (directional signage) for lockers, toilets and changing rooms, as well as window graphics and shop fascia signs."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/wet-coated-acrylic-letters-on-stand-off-locators.jpg"
							title="Wet Coated Acrylic Letters on Stand-Off Locators"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/aluminium-composite-sign-trays--window-graphics.jpg"
							title="Aluminium Composite Sign Trays & Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/AcrylicBackedAluminiumCompositeTray.jpg"
							title="Acrylic Backed Aluminium Composite Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/AcrylicBackedIlluminatedAluminiumCompositeTray.jpg"
							title="Acrylic Backed Illuminated Aluminium Composite Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/AluminiumProjectingLightBox.jpg"
							title="Aluminium Projecting Light Box"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/CutVinylPrivacyFrostedVinyl.jpg"
							title="Cut Vinyl & Privacy Frosted Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/FloorDecals.jpg"
							title="Floor Decals"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/FullColourGraphicsCutVinyl.jpg"
							title="Full Colour Graphics & Cut Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/FullColourWindowGraphics.jpg"
							title="Full Colour Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO.jpg"
							title="FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/FULL_COLOUR_PRINTED_&_WHITE_CUT_HIGH-TACK_VINYL_GRAPHICS.jpg"
							title="FULL_COLOUR_PRINTED_&_WHITE_CUT_HIGH-TACK_VINYL_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS.jpg"
							title="WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/FULL_COLOUR_PRINTED_HIGH-TACK_VINYL.jpg"
							title="FULL_COLOUR_PRINTED_HIGH-TACK_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/BLACK_&_WHITE_HIGH-TACK_VINYL_GRAPHICS.jpg"
							title="BLACK_&_WHITE_HIGH-TACK_VINYL_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/5-Gyms-Fitness/HIGH-TACK_BLACK_CUT_VINYL.jpg"
							title="HIGH-TACK BLACK CUT VINYL"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									On the return from a jet-setting holiday, filled with a cacophony of different
									sweets and treats to delve into, packing on those holiday pounds becomes a growing
									concern for us all! Maybe you start a diet or perhaps you&rsquo;re enjoying that
									holiday weight. For those of us trying to rid ourselves of those pesky pounds,
									often we pop into the gym. There are a variety of things that make a good gym and
									one of them thinks of clarity and convenience. With lots of machinery and heavy
									weights to keep you in shape, a gym, first and foremost, should look out for the
									health and safety of its members.
								</p>

								<p>
									So, what kind of gym and fitness business signage can we offer you? If
									you&rsquo;re looking for informative signage look no further than floor decals
									which are the perfect directional tool to point your members in the direction of
									the appropriate equipment or sanitising stations for our post covid safety
									rituals! But signage is never just limited to the mundane health and safety
									features. Gyms are full of people that may need that added motivation to keep them
									going. After all, for some that first step to the gym is a big deal and keeping
									them in your fitness space will give you a lot of benefits financially! Window
									graphics and full-colour vinyl graphics are the optimal options to add some
									inspirational quotes or helpful tips to keep them going. Full-colour vinyl can be
									customised in a variety of colours, sizes and shapes to articulate whatever brand
									image you wish to convey.
								</p>

								<p>
									We know that gyms act as sanctuaries for many people. A stress reliever after a
									stressful day at work, a place to look sweaty and not be judged or even a space to
									work on self-improvement. Here at Yellow Signs, we believe that some moments
									should be private and safe spaces should be created for these people! That&rsquo;s
									why privacy frosting and graphics are a perfect way to create an enclosed,
									non-judgemental space concerning your guest's privacy. It can be customised from
									full frosting to partial frosting to choose how much you want to show and maintain
									plenty of light.
								</p>

								<p>
									To view, some of our past work, visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									Yellow Signs is a business built in London for London. With many gym members
									working in the city, we know the demand for gyms is high and as such, we want to
									provide you with signage and graphics in London quickly and efficiently so you can
									get up and running and beat the competition. This is why we produce almost
									everything we do in our Battersea production house to supply your signage and
									graphics asap. If you need help with something bespoke or even just need some
									design help, feel free to contact our team who will be more than happy to help you
									out.
								</p>

								<p>
									If you&rsquo;re interested in decking out your fitness space &ndash; using
									graphics and signage &ndash; please contact one of our signage experts at 020 7350
									1567 or email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(GymsFitness);
