import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class FlatLetters extends Component {
	componentDidMount() {
		PointerEvents();
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Flat Cut Acrylic Letters in London, Lasered or Routed Letters</title>
					<meta
						name="description"
						content="Flat Cut Letters in London - We cut flat letters in-house and offer materials including acrylic, aluminium composite, aluminium, steel and wood from Battersea"
					/>
				</Helmet>
				<HeaderSimple
					heading="Flat Cut Letters"
					subtitle="FLAT LETTERS CAN ALSO BE MADE BY ROUTERING TO SHAPE FROM A VARIETY OF WOOD OR METAL AND IDEAL FOR SHOP AND RETAIL SIGNS, ATTACHED DIRECTLY OR WITH STAND-OFF LOCATORS. flat letters are great value and offer a straightforward solution which can work with most brands."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/wet-coated-matt-orange-10mm-acrylic-letters.jpg"
							title="Cut Solid Wood"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/white-5mm-acrylic-letters.jpg"
							title="White 5mm Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/wet-coated-matt-black-5mm-letters-on-stand-off-locators.jpg"
							title="Wet Coated Matt Black 5mm Letters on Stand-Off Locators"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/wet-coated-acrylic-letters-on-stand-off-locators.jpg"
							title="Wet Coated Acrylic Letters on Stand-Off Locators"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/5mm-black-acrylic-letters--clear-acrylic-plaque.jpg"
							title="5mm Black Acrylic Letters & Clear Acrylic Plaque"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/10mm-wet-coated-matt-gray-acrylic-letters.jpg"
							title="10mm Wet Coated Matt Gray Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/wet-coated-matt-black-5mm-acrylic-letters.jpg"
							title="Wet Coated Matt Black 5mm Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/wet-coated-5mm-acrylic-letters-on-stand-off-locators.jpg"
							title="Wet Coated 5mm Acrylic Letters on Stand-Off Locators"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/CutSolidWood.jpg"
							title="Cut Solid Wood"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/StainlessSteelAcrylicLetters.jpg"
							title="Stainless Steel & Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/StainlessSteelFacedAluminiumComposite.jpg"
							title="Stainless Steel Faced Aluminium Composite"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/StainlessSteelFacedAluminiumCompositeLights.jpg"
							title="Stainless Steel Faced Aluminium Composite & Lights"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_WET_COATED_ACRYLIC_LETTERS.jpg"
							title="ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_WET_COATED_ACRYLIC_LETTERS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/GOLD_WET_COATED_5MM_ACRYLIC_LETTERS_APPPLIED_DIRECTLY_TO_FASCIA.jpg"
							title="GOLD_WET_COATED_5MM_ACRYLIC_LETTERS_APPPLIED_DIRECTLY_TO_FASCIA"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/WET_COATED_5MM_ACRYLIC_LETTERS_APPLIED_TO_YELLOW_ALUMINIUM_COMPOSITE_PANEL.jpg"
							title="WET_COATED_5MM_ACRYLIC_LETTERS_APPLIED_TO_YELLOW_ALUMINIUM_COMPOSITE_PANEL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/WEAT_COATED_AND_FULL_COLOUR_PRINTED__CUT_ACRYLIC.jpg"
							title="WET_COATED_AND_FULL_COLOUR_PRINTED__CUT_ACRYLIC"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE.jpg"
							title="WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/WET_COATED_10MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS.jpg"
							title="WET_COATED_10MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/WET_COATED_10MM_ACRYLIC_LETTERS_WITH_FULL_COLOUR_PRINTED_VINYL_TO_FACE.jpg"
							title="WET_COATED_10MM_ACRYLIC_LETTERS_WITH_FULL_COLOUR_PRINTED_VINYL_TO_FACE"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/GOLD_WET_COATED_20MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS__ALUMINIUM_PROJECTING_SIGN.jpg"
							title="GOLD_WET_COATED_20MM_ACRYLIC_LETTERS_ON_STAND-OFF_LOCATORS_+_ALUMINIUM_PROJECTING_SIGN"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/WET_COATED_10MM_ACRYLIC_LETTERS_WITH_25MM_BLACK_FOAM_PROJECTING_SIGN.jpg"
							title="WET_COATED_10MM_ACRYLIC_LETTERS_WITH_25MM_BLACK_FOAM_PROJECTING_SIGN"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/StandOffLocatorLetter.jpg"
							title="Stand-Off Locator Letter"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/StandOffLocatorLetters.jpg"
							title="Stand-Off Locator Letters"
						/>
						<ServicesGalleryImg
							img="/img/signselection/3-Flat-Letters/StandOffLocatorLettersReverse.jpg"
							title="Stand-Off Locator Letters Reverse"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									There&rsquo;s nothing worse than forking out a huge chunk of your marketing budget
									only for it to be ineffective and unnoticed. It&rsquo;s difficult to find a
									cost-effective yet highly effective and creative form of in-person signage without
									it yanking at your purse strings. Whether it&rsquo;s a compromise on creative
									choices or scaling, we believe that every business deserves to put itself in the
									spotlight, either online or in person. That&rsquo;s where we come in. Flat letter
									signage is a fantastic way to elevate your business space without worrying about
									missing out on your customisable options or brand alignment.
								</p>

								<p>
									Flat letters are essentially wall or overhead signage that can be used internally
									or externally to heighten your business appeal and direct consumer attention to
									your business in a sea of lots of shops on the high street. Much like built-up
									letters, Flat letters give off a 3D effect without a back-breaking budget and be
									customised in a variety of shapes from shape, size, colour and stylistic choice.
									Unlike your smaller scale, flat letters are an attractive and aesthetic form of
									visual marketing that can point and inform people in the direction of your
									business.
								</p>

								<p>
									So, let&rsquo;s talk about some of your many options! We&rsquo;ve produced a
									variety of different flat letter products for other businesses and are highly
									experienced in producing the perfect product for a specific business. From
									internal flat letters in a wood detail to aluminium letters for an overhead
									external option. We can offer you straightforward and interesting products that
									can suit your design choices. We produce our flat-letter signage in a variety of
									materials with one of our most popular solutions being acrylic cut lettering,
									which many businesses find much success using. But maybe you want to go the extra
									mile and make something extra striking; stand-off locators are a type of wall
									fixing that can mount your product and create a less flush, and more 3D option of
									signage to pop out and be visually accessible for consumers.
								</p>

								<p>
									For more information on our past work, feel free to visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									As a London-based business, we know just how taxing London prices can be which is
									why we want to offer you competitive and realistic pricing options so you can make
									the most of your budget. We produce almost all of our products in-house in our
									Battersea workshop so we can supply your signage and graphics, especially flat
									letter signage, anywhere in London, in a timely manner, so you can make the most
									of your final product. If you are struggling with ideas or just fancy something a
									bit more bespoke, feel free to contact a member of our team and start an enquiry.
									We are here to help!
								</p>

								<p>
									If you&rsquo;re interested in utilising signage with us &ndash; using graphics and
									signage &ndash; please contact one of our signage experts at 020 7350 1567 or
									email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(FlatLetters);
