import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class CutVinylGraphics extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Trade Signage with In-House Production in Battersea, London</title>
					<meta
						name="description"
						content="Trade Signage - We supply other signage, print and graphics companies as a trade supplier due to our fast turnaround times and strategic location in central London"
					/>
				</Helmet>
				<HeaderSimple
					heading="TRADE SIGNAGE"
					subtitle="We work with and supply other signage, print and graphics companies as a trade supplier due to our fast turnaround times and strategic location in central London."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/Trade/4-COLOUR_3D_PRINTING.jpg"
							title="4-COLOUR_3D_PRINTING"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Trade/CONTOUR_&_KISS-CUT_PRINTING.jpg"
							title="CONTOUR_&_KISS-CUT_PRINTING"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Trade/FRONT,_BACK_&_SIDE_ILLUMINATED_3D_LETTERS.jpg"
							title="FRONT,_BACK_&_SIDE_ILLUMINATED_3D_LETTERS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/Trade/INTERNALLY_ILLUMINATED_BUILT-UP_LETTERS.jpg"
							title="INTERNALLY_ILLUMINATED_BUILT-UP_LETTERS"
						/>
						<ServicesGalleryImg img="/img/signselection/Trade/LASER.jpg" title="LASER" />
						<ServicesGalleryImg img="/img/signselection/Trade/LED_NEON.jpg" title="LED_NEON" />
						<ServicesGalleryImg
							img="/img/signselection/Trade/PAINTED_LETTERS.jpg"
							title="PAINTED_LETTERS"
						/>
						<ServicesGalleryImg img="/img/signselection/Trade/ROUTER.jpg" title="ROUTER" />
						<ServicesGalleryImg
							img="/img/signselection/Trade/UV_CURED_INK.jpg"
							title="UV_CURED_INK"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									We specialise in built-up 3D letters and same-day/next-day turnaround times on UV
									cured print. Our super-fast Mimaki UV printers mean that we can print and deliver
									print same-day in London!
								</p>

								<p>
									We offer supply only as well as supply and installation, with optional plain
									clothes installers.
								</p>

								<p>
									We also utilise our equipment such as router, laser cutter, metal bender, 3D
									printers and spray booth to offer the best prices and fastest turnaround times on
									built-up 3D letters. Our built-up letters can be finished in any RAL or Pantone
									colour and can be front and/or halo illuminated, as well as side illuminated too.
								</p>

								<p>
									For examples of our past works, you can check out{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									If you’re interested in getting a quote for your next project or you’re thinking
									about buying trade signage &ndash; please contact one of our signage experts at
									020 7350 1567 or email{" "}
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>
								<p>
									Alternatively, to request a quick quote visit{" "}
									<a href="https://yellowsigns.co.uk/quote-site-visit">
										yellowsigns.co.uk/quote-site-visit
									</a>{" "}
									to get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(CutVinylGraphics);
