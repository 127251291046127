import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class ShopRetail extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Signs for Shop & Retail Businesses in London</title>
					<meta
						name="description"
						content="Shops and other retail premises can benefit from new signage and graphics both internally and externally, to encourage higher traffic, more customers and more sales in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Shop & Retail"
					subtitle="Shop signs and fascias are important to drive traffic into the premises. As well as aluminium and aluminium composite shop signs there is also a wide range of projecting signs to suit most businesses and their brand. Shops and retail spaces also use window graphics for semi-permanent signage and promotional material."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/pushed-though-acrylic-letters-faced-with-vinyl.jpg"
							title="Pushed Through Acrylic Letters Faced With Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/halo-illuminated-3d-built-up-letters.jpg"
							title="Halo Illuminated 3D Built Up Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/aluminium-illuminated-sign-trays-with-acrylic-letters.jpg"
							title="Aluminium Illuminated Sign Trays With Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/10mm-wet-coated-matt-gray-acrylic-letters.jpg"
							title="10mm Wet Coated Matt Gray Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/full-colour-digitally-printed-window-graphics.jpg"
							title="Full Colour Digitally Printed Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/5mm-black-acrylic-letters--clear-acrylic-plaque.jpg"
							title="5mm Black Acrylic Letters & Clear Acrylic Plaque"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/3d-built-up-letters.jpg"
							title="3D Built Up Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/BevelCutStandOffLetterswithTroughLighting.jpg"
							title="Bevel Cut Stand-Off Letters with Trough Lighting"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/CutFullColourVinyl.jpg"
							title="Cut & Full Colour Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FlatCutAcrylic.jpg"
							title="Flat Cut Acrylic"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FlatCutAcrylicLetters.jpg"
							title="Flat Cut Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FlatCutAcrylicLettersCutVinyl.jpg"
							title="Flat Cut Acrylic Letters & Cut Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FlatCutAcryliconAluminiumCompositeTray.jpg"
							title="Flat Cut Acrylic on Aluminium Composite Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FlatCutLettersTroughLighting.jpg"
							title="Flat Cut Letters & Trough Lighting"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FlatCutVinylAluminiumCompositeTray.jpg"
							title="Flat Cut Vinyl Aluminium Composite Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FullColourGraphics.jpg"
							title="Full Colour Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FullColourSwingProjectingSign.jpg"
							title="Full Colour Swing Projecting Sign"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/PushedThroughAcrylicAluminiumCompositeTray.jpg"
							title="Pushed-Through Acrylic Aluminium Composite Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/PushedThroughAcrylicAluminiumCompositeTray2.jpg"
							title="Pushed-Through Acrylic Aluminium Composite Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/StainlessSteelAcrylicIlluminatedProjectingSign.jpg"
							title="Stainless Steel & Acrylic Illuminated Projecting Sign"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/WET_COATED_ACRYLIC_LETTERS_FROSTING_AND_WINDOW_GRAPHICS.jpg"
							title="WET COATED ACRYLIC LETTERS, FROSTING AND WINDOW GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_WET_COATED_ACRYLIC_LETTERS.jpg"
							title="ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_WET_COATED_ACRYLIC_LETTERS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/BLACK_TINT_APPLIED_TO_INSIDE_OF_GLASS__CUT_WHITE_VINYL_APPLIED_TO_OUTSIDE_OF_GLASS.jpg"
							title="BLACK_TINT_APPLIED_TO_INSIDE_OF_GLASS_+_CUT_WHITE_VINYL_APPLIED_TO_OUTSIDE_OF_GLASS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO.jpg"
							title="FRONT_ILLUMINATION_3D_LETTERS_&_FRET_CUT_LOGO"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FUL_COLOUR_PRINTED_&_WRAPPED_25MM_FOAM_PVC_SWING_PROJECTING_SIGN.jpg"
							title="FUL_COLOUR_PRINTED_&_WRAPPED_25MM_FOAM_PVC_SWING_PROJECTING_SIGN"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FULL_COLOUR_&_WHITE_CUT_VINYL_WINDOW_GRAPHICS.jpg"
							title="FULL_COLOUR_&_WHITE_CUT_VINYL_WINDOW_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FULL_COLOUR_PRINTED_CONTOUR_CUT_WINDOW_GRAPHICS.jpg"
							title="FULL_COLOUR_PRINTED_CONTOUR_CUT_WINDOW_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_PRINTED_SIGN_TRAY.jpg"
							title="PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_PRINTED_SIGN_TRAY"
						/>
						<ServicesGalleryImg
							img="/img/signselection/1-Shop-Retail-Signs/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY__PROJECTING_SIGN.jpg"
							title="PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY_+_PROJECTING_SIGN"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY.jpg"
							title="PUSHED-THROUGH_ACRYLIC_FACED_IN_GOLD_VINYL_INTERNALLY_ILLUMINATED_SIGN_TRAY"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/PUSHED-THROUGH_ACRYLIC_INTERNALLY_ILLUMINATED_SIGN_TRAY_WITH_LETTERS_FACED_IN_VINYL.jpg"
							title="PUSHED-THROUGH_ACRYLIC_INTERNALLY_ILLUMINATED_SIGN_TRAY_WITH_LETTERS_FACED_IN_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/STOCK_BLUE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_PUSHED-THROUGH_ACRYLIC_LETTERS_&_MATCHING_PROJECTING_SIGN.jpg"
							title="STOCK_BLUE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_PUSHED-THROUGH_ACRYLIC_LETTERS_&_MATCHING_PROJECTING_SIGN"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/WET_COATED_GOLD_HALO_ILLUMINATED_3D_LETTERS.jpg"
							title="WET_COATED_GOLD_HALO_ILLUMINATED_3D_LETTERS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS.jpg"
							title="WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE.jpg"
							title="WHITE_ALUMINIUM_COMPOSITE_SIGN_TRAY_WITH_10MM_MATT_BLACK_ACRYLIC_LETTERS_APPLIED_DIRECTLY_TO_FACE"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/8-Shop-Retail/FRONT_ILLUMINATED_3D_BUILT-UP_LETTERS.jpg"
							title="FRONT_ILLUMINATED_3D_BUILT-UP_LETTERS"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									As consumers, we often find ourselves wandering our local shopping centre,
									perusing around the local high street or login into our favourite shopping site on
									our days off. With different industries ever expanding with new products being
									sold, we see a sudden storm of new shop fronts lining our busy streets, especially
									in London. More than ever, business owners like yourself are seeking new and
									interesting ways to diversify themselves from the crowd to make their shop the
									first option for shoppers everywhere. What better way to do this than by updating
									your internal and external graphics game to get all eyes on you?
								</p>

								<p>
									There are a variety of options for business owners to choose from to wow and make
									an impact. One of our most popular options is our custom overhead signage. These
									range in size, colour, material and most importantly price to slip firmly inside
									your budget. From 3D &amp; built-up lettering - to create dimension and both
									literally and metaphorically pop out at shoppers &ndash; all the way to aluminium
									trays and projecting signage to capture audiences from all sorts of angles and
									distances. However, such forms of signage are not limited to your shop front; why
									not involve your interior by bringing some acrylic letters into the game?
								</p>

								<p>
									However, we know that might not be what you&rsquo;re looking for. Fret not! We
									offer lots of other forms of external signage to elevate your externals. Window
									graphics are an extremely popular option for shop fronts as they can be made in
									almost any style, size, design and colour thanks to our full-colour graphics and
									vinyl graphics service to fit any bespoke space. They are the perfect addition to
									any window space as they are fully customisable, to stand out from the crowd. Not
									only this, utilising full-colour window graphics in specific are a great way to
									increase your colour customisation options to match your brand logo or identity
									perfectly.
								</p>

								<p>
									For more information on our past work, please visit:
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									We know that securing the perfect designs is crucial in drawing customers in which
									is why we want to assure you that your products will be completed quickly and
									professionally. Since we produce almost everything we do in-house in our Battersea
									production centre, we have a fast turnaround of products to get your signage and
									graphics anywhere in London. You&rsquo;re more than welcome to contact our team if
									you are seeking something bespoke. Alternatively, if you need any design aid we
									are more than happy to help!
								</p>

								<p>
									If you&rsquo;re interested in decking out your retail space with us &ndash; using
									graphics and signage &ndash; please contact <br />
									one of our signage experts at 020 7350 1567 or email{" "}
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a> <br />
									Alternatively, to request a quick quote visit
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(ShopRetail);
