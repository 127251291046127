import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class ExhibitionEvent extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Exhibition & Event Signs & Graphics in London</title>
					<meta
						name="description"
						content="Exhibition and events can benefit from our super-fast print and signage turnaround times including banners, posters and backdrops for events in and around London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Exhibition & Event"
					subtitle="There is a wide range of signage products for exhibitions and events. Popup portable displays and roller banners, as well and flags and backdrops are popular to help promote the business or a specific product. Wide format prints and lightboxes are perfect for exhibitions and events."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signsbysector/4-Exhibition-Event/cut-vinyl-van-graphics.jpg"
							title="Cut Vinyl Car Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/4-Exhibition-Event/CoatedPolystyrene.jpg"
							title="Coated Polystyrene"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/4-Exhibition-Event/FullColourGraphics.jpg"
							title="Full Colour Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/4-Exhibition-Event/FullColourVinylFoamPVC.jpg"
							title="Full Colour Vinyl & Foam PVC"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/4-Exhibition-Event/FullColourWallpaper.jpg"
							title="Full Colour Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/4-Exhibition-Event/LightBoxGraphics.jpg"
							title="Light Box Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/4-Exhibition-Event/PVCBanners.jpg"
							title="PVC Banners"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/4-Exhibition-Event/WoodFramedFullColourGraphics.jpg"
							title="Wood Framed Full Colour Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/4-Exhibition-Event/ALUMINIUM_COMPOSITE_ENTRANCE_SIGN_DROP.jpg"
							title="ALUMINIUM COMPOSITE ENTRANCE SIGN DROP"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									If you do a quick search online for &lsquo;events near me, your webpage will be
									hounded with hundreds of events - ranging from big to small - all over London,
									ready for the public to take some time out of their day to visit. A common theme
									with these events is graphics. From small-scale logos to large-scale exhibition
									displays; utilising graphic and signage design adds a level of professionalism and
									trust to an event and helps to engage a large crowd. With every event catering to
									different subjects and industries, it can be hard to find the perfect products for
									your business; better yet, it's even harder knowing how they can be used. Luckily
									we are here to make it that little bit easier.
								</p>

								<p>
									A highly popular and extremely diversifiable form of event display is full-colour
									graphics which can be used in a variety of ways. From window graphics to entice
									people into the building to PVC stand-up displays to direct or inform, by choosing
									a customisable form or graphic, you can enhance your event space and help things
									to run smoothly both directionally and informationally. Roller banners and PVC
									banners are a form of signage that can be designed in a variety of sizes and
									colours to display useful information. Perhaps you&rsquo;re producing a food
									exhibition and want passers-by to know exactly when and where they need to be to
									try out your tasty treats. Or maybe you&rsquo;re looking to point people in the
									correct direction of the toilets at a music event without breaking the bank,
									banners are a great way to keep all that much-needed info at eye view to make your
									entire event run smoothly.
								</p>

								<p>
									For more, please visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									However, not all signage and graphics need to be fit for purpose. Sometimes
									it&rsquo;s just fun to have something that looks really cool. We completely
									understand this, and we love your creativity! There are many aesthetic ways to
									translate your vision and create something visually amazing. So what can we offer
									you? Coated polystyrene is a fantastic way of producing portable displays of
									figurines and objects which are great for an interesting photo op. A statue of a
									reindeer for a Xmas fair? Get those Instagram hashtags rolling in and make your
									event the place to be!
								</p>

								<p>
									Where do we come in? as a London-based company we know all about the event
									circuit. We know exactly what works and how to make it. Our highly experienced
									team are also more than happy to help with bespoke requests. We produce almost all
									of everything we do in our Battersea production space meaning we have a quick
									turnaround of products, getting your signage and graphics, especially for events
									and exhibitions, in London ready to go.
								</p>

								<p>
									If you&rsquo;re interested in decking out your event with us &ndash; using
									graphics and signage &ndash; please contact one of our signage experts on 020 7350
									1567 or email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(ExhibitionEvent);
