import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class PrivacyFrosting extends Component {
	componentDidMount() {
		PointerEvents();
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Privacy Frosting in London & Frosted Vinyl in London</title>
					<meta
						name="description"
						content="Privacy Frosting & Frosted Vinyl in London - Privacy frosting and manifestations for office in and around London, either blank frost, printed frost or cut frost"
					/>
				</Helmet>
				<HeaderSimple
					heading="Privacy Frosting"
					subtitle="FROSTED WINDOW FILM IS USED TO PROVIDE PRIVACY IN OFFICES AND INDUSTRIES SUCH MEDICAL SERVICES. FROSTED FILM CAN BE CUT AND PRINTED ON TO ALMOST ANY SHAPE OR SIZE. FROSTING IS OFTEN REQUIRED IN OFFICES AS PART OF HEALTH & SAFETY MEASURES."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/cut-to-shape-frosted-vinyl--cut-orange-vinyl.jpg"
							title="Cut to Shape Frosted Vinyl + Cut Orange Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/ContourCutFrostedVinyl.jpg"
							title="Contour Cut Frosted Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/ContourCutFrostedVinyl2.jpg"
							title="Contour Cut Frosted Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/FullColourPrintedFrostedVinyl.jpg"
							title="Full Colour Printed Frosted Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/PrintedFrostedVinyl.jpg"
							title="Printed Frosted Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/ReverseCutForstedVinyl.jpg"
							title="Reverse Cut Frosted Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/ReverseCutForstedVinyl2.jpg"
							title="Reverse Cut Frosted Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/ReverseCutFrostedVinyl.jpg"
							title="Reverse Cut Frosted Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS.jpg"
							title="WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/FULL_COLOUR_PRINTED_VINYL_MANIFESTATIONS.jpg"
							title="FULL_COLOUR_PRINTED_VINYL_MANIFESTATIONS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/CUT_PRIVACY_FROSTED_VINYL.jpg"
							title="CUT_PRIVACY_FROSTED_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/9-Frosted-Privacy-Vinyl/FULL_COLOUR_PRINTED_FROSTED_VINYL.jpg"
							title="FULL_COLOUR_PRINTED_FROSTED_VINYL"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Brands need to be enigmatic; parts of your business need to be heard and not seen.
									Privacy is paramount to every business in order to evolve and grow without those
									glaring, prying eyes. Whether you&rsquo;re an office-based business that wants to
									keep its inner workings private or a shopfront business that seek to offer your
									customers privacy and security, it&rsquo;s vital to provide the perfect working
									and business environment for your employees and clients. Luckily, we offer a range
									of options for those private moments in business and one of them is our privacy
									frosting service.
								</p>

								<p>
									So, what is Privacy frosting? Privacy frosting does exactly what it says on the
									tin; essentially, it's a window-based sticker editable according to your privacy
									and colour needs and offers an etched and blurred screen to block the visuals from
									both directions. They are low maintenance and easily applied graphics that are
									self-adhered for high quality and clean alternatives to nets, blinds and curtains.
									They can come self-adhered and removable, if you&rsquo;re looking for something on
									a more temporary basis, for example, a rental space. Or maybe you want to change
									your design as your business evolves and want to be able to create something
									interchangeable or diverse. Whatever your needs, we are happy to lend a helping
									hand.
								</p>

								<p>
									At Yellow Signs, we believe you should have your cake and eat it too and believe
									your privacy shouldn&rsquo;t compromise your creativity. Therefore, we offer a
									variety of designs and colours to translate whatever image of your business you
									want whilst still leaving plenty to the imagination. The frosting also
									doesn&rsquo;t need to be one or nothing, so we provide partial frosting methods
									including frosted outlines of images or words to prevent your business or domestic
									space from looking flat and lifeless.
								</p>

								<p>
									For some of our past work, please check out:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									We understand the need for a quick and final product which is why we produce
									almost all of everything we do in-house, in our Battersea workshop, to supply your
									products quickly and timely. Our team also know how stressful it is to find and
									source a team with-= a high concentration of services in and around London; so,
									rest assured that our highly experienced and creative team can get your signage
									and graphics, such as privacy frosted graphics, anywhere you are in London. We are
									also happy to help with any bespoke or more detailed services you are looking for!
								</p>

								<p>
									If you&rsquo;re interested in working with us &ndash; using graphics and signage
									&ndash; please contact one of our signage experts on 020 7350 1567 or email{" "}
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(PrivacyFrosting);
