import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class DigitalWallpaper extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Digital Wallpaper in London, Full Colour Printing in Battersea</title>
					<meta
						name="description"
						content="Full Colour Printed Digital Wallpaper - We fabricate digital wallpaper in-house in our Battersea production centre and can supply and install anywhere in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Digital Wallpaper"
					subtitle="FULL COLOUR PRINTED WALLPAPER CAN BRING ANY WALL TO LIFE WITH ANY DESIGN YOU SUPPLY. SUITABLE FOR FLAT SURFACES SUCH AS SEALED PAINTED WALLS AND GLASS. PRINTED WALLPAPER CAN TRANSFORM SPACES IN COMMERCIAL AND DOMESTIC ENVIRONMENTS AND CAN REPLACE THE NEED TO PAINT."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/10-Full-Colour-Wallpaper/full-colour-digitally-printed-wallpaper.jpg"
							title="Full Colour Digitally Printed Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signselection/10-Full-Colour-Wallpaper/CutVinylFullColourWallpaper.jpg"
							title="Cut Vinyl & Full Colour Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signselection/10-Full-Colour-Wallpaper/FullColourPartialWrapWallpaper.jpg"
							title="Full Colour Partial Wrap Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signselection/10-Full-Colour-Wallpaper/FullColourWallpaper2.jpg"
							title="Full Colour Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signselection/10-Full-Colour-Wallpaper/FullColourWallpaper3.jpg"
							title="Full Colour Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signselection/10-Full-Colour-Wallpaper/FullColourWallpaper4.jpg"
							title="Full Colour Wallpaper"
						/>
						<ServicesGalleryImg
							img="/img/signselection/10-Full-Colour-Wallpaper/FullColourWallpaperCounterWrap.jpg"
							title="Full Colour Wallpaper & Counter Wrap"
						/>
						<ServicesGalleryImg
							img="/img/signselection/10-Full-Colour-Wallpaper/FullColourWallpaperFloorGraphics.jpg"
							title="Full Colour Wallpaper & Floor Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/10-Full-Colour-Wallpaper/MapFullColourWallpaper.jpg"
							title="Map Full Colour Wallpaper"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Businesses are more than just the four walls of a building. It&rsquo;s about the
									brand ethos, the people that work in the company and most importantly, the
									customers. However, there is no harm in making those four walls align with your
									business identity with personalised graphics to heighten your business visibility.
									How can you achieve this? Let&rsquo;s talk about digital wallpaper. <br />
									<br />
									Firstly, what is digital wallpaper? Well, it&rsquo;s exactly what it says on the
									tin. Digital wallpaper is a wallpaper that can be adorned with custom designs,
									prints colours and imagery. Unlike your usual shop-bought products, digital
									wallpaper is made to measure and is printed in short runs, meaning you don&rsquo;t
									get that unfortunate extra stock lying around, collecting dust. It allows you more
									scope for creativity and can align with your business brand image, logos or colour
									palette. <br />
									<br />
									If we handed you a canvas and asked you to draw your business values, what would
									it say? Digital wallpaper is your blank canvas and acts as personalised artwork
									for your business. Are you trying to get your company noticed and want to advance
									your social media presence? There's nothing a gen Z or millennial loves more than
									an insta-worthy snap. So, make your business a backdrop worthy of being tagged and
									draw in a new customer base. But hey, sometimes it&rsquo;s not just about
									business, maybe you just want to make your space a beautiful place to be, and who
									could blame you? However, digital wallpaper isn't exclusive to businesses alone;
									domestic settings can also be accented with brand-new bespoke graphics for
									aesthetic purposes as is perfect for rental spaces. Our highly experienced team
									are happy to help with your requests.
								</p>

								<p>
									To see some of our previous work, please visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									Now for the technical: digital wallpaper can be applied to any flat surface
									including walls, and glass to elevate business spaces. They are easy to remove so
									are perfect for temporary spaces or special events and promotions making it quick
									and easy to switch in and out. At Yellow Signs, we can produce your products with
									a quick turnaround since we create almost everything we do, in-house. This means
									that we can supply your signage and graphics, including digital wallpaper,
									anywhere in London, as quickly as possible. If you are struggling to find the
									winning design you&rsquo;re looking for and need some assistance in creating your
									perfect installation, our team are more than happy to help you to figure something
									out we are sure you will love!
								</p>

								<p>
									If you&rsquo;re interested in redesigning your business with us &ndash; using
									graphics and signage &ndash; please contact one of our signage experts on 020 7350
									1567 or email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(DigitalWallpaper);
