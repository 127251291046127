import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import axios from "axios";
import Header from "./fragments/common/Header";
import Footer from "./fragments/common/Footer";
import Home from "./pages/Home";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// Sign Selection
import ShopsRetailSigns from "./pages/signselection/ShopsRetailSigns";
import NeonSigns from "./pages/signselection/NeonSigns";
import VehicleGraphics from "./pages/signselection/VehicleGraphics";
import AcrylicLetters from "./pages/signselection/AcrylicLetters";
import PlaquesWayfinding from "./pages/signselection/PlaquesWayfinding";
import FlatLetters from "./pages/signselection/FlatLetters";
import FullColourGraphics from "./pages/signselection/FullColourGraphics";
import PrivacyFrosting from "./pages/signselection/PrivacyFrosting";
import ProjectingSigns from "./pages/signselection/ProjectingSigns";
import ThroughLighting from "./pages/signselection/ThroughLighting";
import CutVinylGraphics from "./pages/signselection/CutVinylGraphics";
import DigitalWallpaper from "./pages/signselection/DigitalWallpaper";
import Hoarding from "./pages/signselection/Hoarding";
import BespokeFabrication from "./pages/signselection/BespokeFabrication";
import Print from "./pages/signselection/Print";
import Trade from "./pages/signselection/Trade";

// Signs by Sector
import BarRestaurant from "./pages/signsbysector/BarRestaurant";
import BuildConstruction from "./pages/signsbysector/BuildConstruction";
import EstateAgencyProperty from "./pages/signsbysector/EstateAgencyProperty";
import ExhibitionEvent from "./pages/signsbysector/ExhibitionEvent";
import GymsFitness from "./pages/signsbysector/GymsFitness";
import OfficeCorporate from "./pages/signsbysector/OfficeCorporate";
import SchoolEducation from "./pages/signsbysector/SchoolEducation";
import ShopRetail from "./pages/signsbysector/ShopRetail";

// About Us
import OurStory from "./pages/aboutus/OurStory";
import MeetTheTeam from "./pages/aboutus/MeetTheTeam";
import ContactUs from "./pages/aboutus/ContactUs";

// What We Do
import SignageSolution from "./pages/whatwedo/SignageSolution";
import CaseStudies from "./pages/whatwedo/CaseStudies";
// import Articles from "./pages/whatwedo/Articles";
import News from "./pages/whatwedo/News";
import SinglePost from "./pages/whatwedo/SinglePost";

// Top level pages
import QuoteSiteVisit from "./pages/QuoteSiteVisit";
import SignBuyersGuide from "./pages/SignBuyersGuide";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			posts: {},
			postUrls: [],
		};
	}

	componentDidMount() {
		// Fetching posts immediatelly from WP for speedy delivery accross page
		axios.get(`https://blog.yellowsigns.co.uk/wp-json/wp/v2/posts?per_page=30`).then((res) => {
			this.setState({ posts: res.data });
		});
		// axios.get(`https://www.wpexplorer.com/wp-json/wp/v2/posts?per_page=30`).then((res) => {
		// 	this.setState({ posts: res.data });
		// });
	}

	render() {
		let postsFetched = Object.keys(this.state.posts).length !== 0;

		return (
			<Router>
				<Header />

				<Switch>
					{/* Sign Selection */}
					<Route exact path="/shops-retail-signs" component={ShopsRetailSigns} />
					<Route exact path="/neon-signs" component={NeonSigns} />
					<Route exact path="/vehicle-graphics" component={VehicleGraphics} />
					<Route exact path="/3d-builtup-letters" component={AcrylicLetters} />
					<Route exact path="/digital-wallpaper" component={DigitalWallpaper} />
					<Route exact path="/plaques-wayfinding" component={PlaquesWayfinding} />
					<Route exact path="/flat-letters" component={FlatLetters} />
					<Route exact path="/full-colour-graphics" component={FullColourGraphics} />
					<Route exact path="/privacy-frosting" component={PrivacyFrosting} />
					<Route exact path="/projecting-signs" component={ProjectingSigns} />
					<Route exact path="/trough-lighting" component={ThroughLighting} />
					<Route exact path="/cut-vinyl-graphics" component={CutVinylGraphics} />

					<Route exact path="/hoarding" component={Hoarding} />
					<Route exact path="/bespoke-fabrication" component={BespokeFabrication} />
					<Route exact path="/print" component={Print} />
					<Route exact path="/trade" component={Trade} />

					{/* Signs by Sector */}
					<Route exact path="/bar-restaurant" component={BarRestaurant} />
					<Route exact path="/build-construction" component={BuildConstruction} />
					<Route exact path="/estate-agent-property" component={EstateAgencyProperty} />
					<Route exact path="/exhibition-event" component={ExhibitionEvent} />
					<Route exact path="/gyms-fitness" component={GymsFitness} />
					<Route exact path="/office-corporate" component={OfficeCorporate} />
					<Route exact path="/school-education" component={SchoolEducation} />
					<Route exact path="/shop-retail" component={ShopRetail} />

					{/* About us */}
					<Route exact path="/our-story" component={OurStory} />
					<Route exact path="/meet-the-team" component={MeetTheTeam} />
					<Route exact path="/contact-us" component={ContactUs} />

					{/* What We Do */}
					<Route exact path="/signage-solution" component={SignageSolution} />
					<Route exact path="/case-studies">
						<CaseStudies slug="/case-studies" {...this.state} />
					</Route>
					<Route exact path="/news">
						<News slug="/news" {...this.state} />
					</Route>

					{/* Blog Pages Single */}
					{postsFetched
						? this.state.posts.map((item, index) => (
								<Route path={`/${item.slug}`} key={index}>
									<SinglePost
										title={item.title.rendered}
										date={item.date}
										img={item.jetpack_featured_media_url}
										content={item.content.rendered}
										category={item.categories[0] === 2 ? "News" : "Case Studies"}
										recentPosts={this.state.posts}
									/>
								</Route>
						  ))
						: null}

					{/* Home & Top Level Pages */}
					<Route exact path="/sign-buyers-guide" component={SignBuyersGuide} />
					<Route exact path="/quote-site-visit" component={QuoteSiteVisit} />
					<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					<Route exact path="/terms-and-conditions" component={TermsConditions} />
					<Route exact path="/" component={Home} />

					<Route path="*">
						<div style={{ padding: "10rem", textAlign: "center" }}>
							<Loader type="Grid" color="#ffd800" height={80} width={80} timeout={4000} />
						</div>
					</Route>
				</Switch>

				<Footer />
			</Router>
		);
	}
}
