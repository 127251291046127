import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class CutVinylGraphics extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Cut Vinyl Graphics in London, Window Graphics Specialist</title>
					<meta
						name="description"
						content="Cut Vinyl Signage in London - Cut vinyl is a good value and flexible solution as the range of colours is huge - we fabricate in-house and cover all of London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Cut Vinyl Graphics"
					subtitle="CUT VINYL IS IDEAL FOR SOLID COLOUR DESIGNS AND SUITABLE FOR SMOOTH SURFACES SUCH AS GLASS, METAL, GLOSS PAINTED WOOD, WINDOWS AND VEHICLES. iT IS A GREAT VALUE SOLUTION AND GIVES A CRISP AND PUNCHY COLOUR WHICH CAN BE CUT TO ALMOST ANY SIZE OR SHAPE."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/cut-vinyl-letters--suspended-clear-acrylic-roundel.jpg"
							title="Cut Vinyl Letters & Suspended Clear Acrylic Roundel"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/cut-vinyl-window-graphics.jpg"
							title="Cut Vinyl Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/CutVinylFrostedPrivacyWindowVinyl.jpg"
							title="Cut Vinyl & Frosted Privacy Window Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/CutVinylFullColourWindowGraphics.jpg"
							title="Cut Vinyl & Full Colour Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/CutVinylGraphics.jpg"
							title="Cut Vinyl Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/CutVinylGraphics2.jpg"
							title="Cut Vinyl Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/CutVinylWindowGraphics2.jpg"
							title="Cut Vinyl Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/WHITE_CUT_VINYL.jpg"
							title="WHITE_CUT_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/HIGH-TACK_WHITE_CUT_VINYL.jpg"
							title="HIGH-TACK_WHITE_CUT_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/WHITE_CUT_VINYL_OPENING_TIMES.jpg"
							title="WHITE_CUT_VINYL_OPENING_TIMES"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/TEAL_CUT_VINYL_WINDOW_GRAPHICS.jpg"
							title="TEAL_CUT_VINYL_WINDOW_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS.jpg"
							title="WET_COATED_ACRYLIC_LETTERS,_FROSTING_AND_WINDOW_GRAPHICS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/BLACK_TINT_APPLIED_TO_INSIDE_OF_GLASS__CUT_WHITE_VINYL_APPLIED_TO_OUTSIDE_OF_GLASS.jpg"
							title="BLACK_TINT_APPLIED_TO_INSIDE_OF_GLASS_+_CUT_WHITE_VINYL_APPLIED_TO_OUTSIDE_OF_GLASS"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/FULL_COLOUR_PRINTED_HIGH-TACK_VINYL.jpg"
							title="FULL_COLOUR_PRINTED_HIGH-TACK_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/BLACK_CUT_VINYL.jpg"
							title="BLACK_CUT_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/ANTI-GRAFFITI_HOARDING.jpg"
							title="ANTI-GRAFFITI_HOARDING"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/FULL_COLOUR_PRNITED_VINYL__WHITE_CUT_VINYL.jpg"
							title="FULL_COLOUR_PRNITED_VINYL_+_WHITE_CUT_VINYL"
						/>
						<ServicesGalleryImg
							img="/img/signselection/7-Cut-Vinyl-Graphics/RED_CUT_VINYL.jpg"
							title="RED_CUT_VINYL"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Let&rsquo;s face it, signage and graphics can get pricey! With production,
									installation and a cacophony of shapes, colours, sizes and materials to choose
									from, translating your brand image can become a pretty difficult task when you
									have budget restrictions; luckily, we are here to help. At Yellow Signs, we
									don&rsquo;t want to sell you signs, we want to sell you a vision of exactly what
									they can do for your business; we want to offer you the creative freedom to
									represent your business in the way you wish without breaking the bank.
								</p>

								<p>
									So, what can we offer you? If you&rsquo;re looking for something long-lasting and
									highly customisable, look no further than cut vinyl graphics. They&rsquo;re the
									bread and butter of graphic signage and can completely transform your business
									space both internally, externally and everything in between. From colourful and
									eclectic window decals - to entice new customers and give your space life - to
									wall graphics to represent your brand identity in pictures or text. Since cut
									vinyl is easy to diversify, we can produce every shape under the sun in a variety
									of colours to highlight your business in the best way we know how.
								</p>

								<p>
									Cut vinyl graphics work by taking adhesive-coloured vinyl and customising them to
									your every whim. This means they're long-lasting yet extremely easy to remove.
									There are plenty of options for vinyl graphics including window decals - to draw
									customers from the outside in - or brand-new wall art to temporarily spice up a
									rental space or permanent space once you get them in the door; cut-vinyl graphics
									are water resistant and can be easily cleaned and maintained, making them one of
									the most cost-efficient and good value form of signage for any business.
								</p>

								<p>
									What can we do? We know that London can be heinous for businesses and making your
									business stand out is vital in bolstering your sales and experiences, so we want
									to ensure your product is produced and to your location as quickly as possible.
									Our highly skilled team produce almost all of our products, especially cut-vinyl
									graphics, in our Battersea HQ meaning we can skip those pesky waiting times and
									get your signage and graphics, including cut-vinyl, to you, anywhere in London, at
									the drop of a pin! If you need something more bespoke or are struggling for
									inspiration, feel free to contact a member of our team to help get you started.
								</p>

								<p>
									To see some of our past work, feel free to visit our case studies page at:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link> to give you
									some inspo.
								</p>

								<p>
									If you&rsquo;re interested in decking out your event with us &ndash; using
									graphics and signage &ndash; please contact one of our signage experts at 020 7350
									1567 or email <a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(CutVinylGraphics);
