import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class EstateAgencyProperty extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Signs for Estate Agent & Property Businesses in London</title>
					<meta
						name="description"
						content="Estate agents can benefit from new signage and graphics to encourage new clients and reinforce company branding - we supply and install estate agent signage in London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Estate Agent & Property"
					subtitle="Single or multi-branch estate agencies often use many types of signage – from vehicle graphics and window graphics to shop fascias and projecting signs. Property refurbishment companies often use outdoor PVC banners, as well as hoarding and foam PVC boards."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/illuminated-aluminium-pushed-through-acrylic-sign-tray.jpg"
							title="Illuminated Aluminium Pushed Through Acrylic Sign Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/full-colour-digitally-printed-window-graphics-1.jpg"
							title="Full Colour Digitally Printed Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/full-colour-digitally-printed-window-graphics-2.jpg"
							title="Full Colour Digitally Printed Window Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/AcrylicBackedAluminiumCompositeTray.jpg"
							title="Acrylic Backed Aluminium Composite Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/CutVinylCarGraphics.jpg"
							title="Cut Vinyl Car Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/CutVinylFacedAluminiumCompositeTray.jpg"
							title="Cut Vinyl Faced Aluminium Composite Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/CutVinylFullColourCarGraphics.jpg"
							title="Cut Vinyl & Full Colour Car Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/CutVinylStainlessSteelPlaque.jpg"
							title="Cut Vinyl Stainless Steel Plaque"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/FlatCutAcrylicBrushedStainlessSteelLetters.jpg"
							title="Flat Cut Acrylic & Brushed Stainless Steel Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/FlatCutAcrylicLettersonAluminiumTray.jpg"
							title="Flat Cut Acrylic Letters on Aluminium Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/FlatCutBlueBrushedStainlessSteelLetters.jpg"
							title="Flat Cut Blue Brushed Stainless Steel Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/FlushAcrylicAluminiumTray.jpg"
							title="Flush Acrylic Aluminium Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/FullColourCarWrap.jpg"
							title="Full Colour Car Wrap"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/PartialWrapCarGraphics.jpg"
							title="Partial Wrap Car Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/3-Estate-Agent-Property/PushedThroughAcrylicAluminiumCompositeTray.jpg"
							title="Pushed-Through Acrylic Aluminium Composite Tray"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									Imagine you&rsquo;re a couple looking to purchase your first home; a simple search
									on Google for &lsquo;estate agents near me&rsquo; can lead you to a wild
									assortment of businesses making different promises which can undoubtedly be
									annoying. Estate agents in the UK started in the Capital so the industry is
									highly-saturated with lots of agencies trying to get ahead of the curve. So what
									makes your business different? Do you offer competitive prices or have you won
									industry awards that make you more notable than the rest? This NEEDS to be seen.
									Let us help you create incredible visuals that are sure to impress.
								</p>

								<p>
									Where to begin? Let's start with externals. Overhead signage is almost always the
									first part of a business a consumer will look at on the high street. It lists the
									name of your business and draws their attention from others shops to yours and
									what it can offer them. From built-up trays to flat letter acrylics - that will
									impress from close and far distances - to window graphics that can list your many
									achievements and stats, choosing high-quality and well-designed outdoor signage
									and graphics is vital in getting customers to choose your business as their
									destination of choice.
								</p>

								<p>
									Your advertising doesn&rsquo;t finish with your business space. As people that
									work in property, you will know that getting people through the door doesn&rsquo;t
									mean the property sells itself. There are other ways you can enhance your customer
									experience and foster a sense of reliability and brand authenticity. One way is by
									using your vehicles as portable advertising. If your business is lucky enough to
									utilise company vehicles, you can add cut vinyl graphics and wraps to cover your
									vehicle in your brand image and ensure your customers know exactly who's at their
									doorstep. Not only this but vehicles are constantly seen whether they're on the
									move or parked up on the roadside. This means they are seen; it only takes one
									person to take an interest in your business to bolster a sale.
								</p>

								<p>
									So, how can we help? As business routed in London, we know how London works.
									Whether it&rsquo;s identifying what form of visual marketing works the best in the
									area, to the best product or products for your business space, we want to make
									your experience as simplistic as possible. This is why we produce almost
									everything we do in our Battersea production house so we can complete a quick
									turnaround of products to supply your winning signage and graphics, especially for
									the property industry, anywhere in London in little to no time.
								</p>

								<p>
									To view, some of our past work, visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									If you&rsquo;re interested in working with us &ndash; using graphics and signage
									&ndash; please contact one of our signage experts at 020 7350 1567 or email{" "}
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(EstateAgencyProperty);
