import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderSimple from "../../fragments/services/HeaderSimple";
import ServicesGalleryImg from "../../fragments/services/ServicesGalleryImg";
import PointerEvents from "../../fragments/common/PointerEvents";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import GalleryText from "../../fragments/services/GalleryText";
import { Helmet } from "react-helmet";

class BuildConstruction extends Component {
	componentDidMount() {
		PointerEvents("signSelectionBtn");
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Signs for Building & Construction in London, Yellow Signs</title>
					<meta
						name="description"
						content="Building and construction companies can benefit from hoarding and other types of signage and graphics to advertise and market their business in and around London"
					/>
				</Helmet>
				<HeaderSimple
					heading="Building & Construction"
					subtitle="Internal signage for the building and construction industry usually includes wayfinding (directional signage), window graphics and floor graphics. External signage usually includes aluminium composite hoarding which is designed to cover up building sites and offer advertising and marketing space for the project or the building company. PVC Banners are also popular for smaller projects."
				/>

				<Container>
					<Row>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/white-5mm-acrylic-letters.jpg"
							title="White 5mm Acrylic Letters"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/AcrylicBackedAluminiumCompositeTray.jpg"
							title="Acrylic Backed Aluminium Composite Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/CutFullColourVinylVehicleGraphics.jpg"
							title="Cut & Full Colour Vinyl Vehicle Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/CutVinyl.jpg"
							title="Cut Vinyl"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/CutVinylAluminiumCompositeHoarding.jpg"
							title="Cut Vinyl Aluminium Composite Hoarding"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/CutVinylJCBGraphics.jpg"
							title="Cut Vinyl JCB Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/FlatCutVinylTruckGraphics.jpg"
							title="Flat Cut Vinyl Truck Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/FullColourVehicleGraphics.jpg"
							title="Full Colour Vehicle Graphics"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/PVCBanner.jpg"
							title="PVC Banner"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/VehicleGraphics3.jpg"
							title="3D Built-Up Letters on Tray"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/VEHICLE_GRAPHICS_AND_CHEVRONS.jpg"
							title="VEHICLE GRAPHICS AND CHEVRONS"
						/>
						<ServicesGalleryImg
							img="/img/signsbysector/2-Building-Construction/BLACK_CUT_VINYL_VEHICLE_GRAPHICS.jpg"
							title="BLACK CUT VINYL VEHICLE GRAPHICS"
						/>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<GalleryText>
								<p>
									If you stroll down your local town centre or high street, chances are you&rsquo;ll
									spot at least one if not more construction sites busily being readied for their
									purpose. Often you will notice the huge scaffolding structures or the sound of
									builders drilling and hammering until they clock off from work. As humans we are
									naturally curious: when we see something new in our local area we want to know
									what it is and if is it something we want to or should invest our time in. As we
									said, building and construction surround us; so much so, that it becomes a perfect
									place to market a new building, business or project, even before the build has
									started and is completed.
								</p>

								<p>
									We&rsquo;ve made the point so let&rsquo;s back it up. There are a variety of
									options for signage and graphics your business or construction site can utilise to
									create brand awareness during your building process. After all, the journey is
									just as important as the destination. Let's talk options for those building sites,
									covered head to toe in scaffolding, PVC banners can play a big role in
									representing your business's work. If a passer-by sees the work ethic or quality
									of your building team or production method, what better way to engage and offer
									your services than by advertising off the back of your current projects?
								</p>

								<p>
									What's more? Some projects are, needless to say, much more dangerous than others.
									With large-scale builds often requiring more intensive work, health and safety
									require levels of protective barriers to keep members of the public safe from
									debris and danger. Hoardings are the perfect non-invasive barrier for your
									business and act as a signpost for your business, perfect for displaying contact
									information, blueprints or projected results! However, building sites aren&rsquo;t
									the only area you can signpost in the construction industry. Vehicles and shop
									fronts play a vital role in the way in which we receive our marketing. From
									aluminium overhead trays to vehicle wraps and graphics, using your everyday
									business investment as a form of 24/7 advertisement is a great way to drive sales.
								</p>

								<p>
									For more information on our past work, please visit:{" "}
									<Link to="case-studies">https://yellowsigns.co.uk/case-studies</Link>
								</p>

								<p>
									How can we help? As a London-based business, we know the demand in the
									construction industry is intense and requires quick turnarounds of products.
									Luckily, we produce almost everything we do in-house in our Battersea workshop
									meaning we can get your signage and graphics anywhere in London quickly so you can
									focus on making your projects everything you need them to be. If you would like
									help producing something more bespoke, feel free to contact a member of our
									qualified team to get started; alternatively, if you need any design help we are
									happy to help!
								</p>

								<p>
									If you&rsquo;re interested in working with us &ndash; using graphics and signage
									&ndash; please contact one of our signage experts at 020 7350 1567 or email
									<a href="mailto:info@yellowsigns.co.uk">info@yellowsigns.co.uk</a>
								</p>

								<p>
									Alternatively, to request a quick quote visit{" "}
									<Link to="quote-site-visit">https://yellowsigns.co.uk/quote-site-visit</Link> to
									get started!
								</p>
							</GalleryText>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(BuildConstruction);
